import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { TopBtnSVG } from '../../../assets/icons/top-btn.svg';
import styles from './top-buttton.module.scss';
interface TopButtonProps {
    children?: React.ReactNode,
    className?: string,
}

const TopButton: React.FC<TopButtonProps> = ()=> {
    const [isVisible, setVisible]= useState(false);
    const [viewport, setViewPort] = useState<number>(300);

    useEffect(() => {
        const toggleVisibility = () => {
            if (typeof window !== 'undefined') {
                setViewPort(window.document.body.offsetHeight);
                if (window.pageYOffset >= viewport) {
                    setVisible(true);
                } else {
                    setVisible(false);
                }
            }
        };
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [viewport]);

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };    
    
    const variants = {
        initial: { opacity: 0, cursor: 'default'},
        animate: {
          opacity: 1,
          cursor:'pointer',
          transition: {
           duration: .7, ease: 'linear'
          }, 
        }
      };   

    return (
        <motion.button
            onClick={scrollUp}
            className={styles['top-btn-wrapper']}
            variants={variants}
            initial="initial"
            animate={isVisible? 'animate' : 'initial'}
        > 
            <TopBtnSVG/>
        </motion.button>
    )
};

export default TopButton;