import React, {useEffect} from 'react';
import {useToasts} from 'react-toast-notifications';

import {useErrorCodes} from '../../../api/hooks/useErrorCodes.hook';
import {ResponseCodes} from '../../../utils/_enums/response-codes.enum';
import {ToasterTypes} from '../../../utils/_enums/toaster-types.enum';
import {useAppSelector} from '../../../utils/_hooks/hooks';

const filteredErrorCodes = [
    ResponseCodes.CHECKOUT_NOT_ON_ALLOWLIST_MAIL,
    ResponseCodes.CHECKOUT_NOT_ON_ALLOWLIST_MAIL_AND_WALLET
];

const GlobalToast = () => {
    const errorCodeStrings = useErrorCodes();
    const {errorCodes} = useAppSelector((state) => ({errorCodes: state.globalSlice.errorCodes}))
    const {addToast} = useToasts();

    const mapErrorCodeToString = (errorCode: number): string => {
        const errorObject = errorCodeStrings.find(errorObject => errorObject.code === errorCode);
        return errorObject ? errorObject.message :
            errorCodeStrings.find(errorObject => errorObject.code === 0)?.message ?? '';
    }

    useEffect(() => {
        if (errorCodes.length > 0 && !filteredErrorCodes.some(filteredErrorCode => filteredErrorCode === errorCodes[errorCodes.length - 1])) {
            addToast(mapErrorCodeToString(errorCodes[errorCodes.length - 1]), {
                appearance: ToasterTypes.ERROR
            });
        }
    }, [errorCodes]);

    return <div/>;
}

export default GlobalToast;

GlobalToast.dataHooks = [useErrorCodes];
