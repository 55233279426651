import React from 'react';

export const TopBtnSVG: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
    return (
            <svg xmlns="http://www.w3.org/2000/svg" 
              height={80.54}
              width={77.5} 
              viewBox="0 0 80.54 77.5"
              {...props}
            >
              <g id="top_btn" data-name="top btn" transform="translate(-306.947 -586.031)">
                <path id="Pfad_1747" data-name="Pfad 1747" d="M46.5,0h-31L0,26.847,15.5,53.693h31L62,26.847Z" 
                  width="50" height="50"
                  transform="translate(306.947 617.031) rotate(-30)" fill="#42aaff"/>
                <g id="icons_Arrow_Down_2_Line" data-name="icons/Arrow Down 2/Line" transform="translate(365.848 638.865) rotate(180)">
                  <path id="vector_Stroke_" data-name="vector (Stroke)" d="M14.18,0l5.684,4.588L14.18,9.261v-3.6L0,5.668,1.189,3.589,14.18,3.461Z" transform="translate(23.261 5) rotate(90)" fill="#fff"/>
                </g>
              </g>
            </svg>

    );
};
