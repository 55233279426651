import {Action, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {saveAs} from 'file-saver';

import {TransactionsFormData} from '../../api/models/transactions-form-data';
import AccountingReportControllerService from '../../api/services/accounting-report-controller.service';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';

interface AccoutingReportState {
    pending: boolean;
}

const initialState: AccoutingReportState = {
    pending: false
};

const isSlicePending = (action: Action) => {
    return action.type.startsWith('accountingReport') && action.type.endsWith('pending');
}

const isSliceFinished = (action: Action) => {
    return action.type.startsWith('accountingReport')
        && (action.type.endsWith('rejected') || action.type.endsWith('fulfilled'));
}

export const fetchAccountingReports = createAsyncThunk(
    'accountingReport/fetchAccountingReports',
    async (data: TransactionsFormData, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const accountingReportController = new AccountingReportControllerService();
            const allPublishers = data.publisher.some(publisher => publisher === -1);
            const res = await accountingReportController.getAccountingReport(
                data.dateFrom,
                data.dateTo,
                allPublishers ? undefined : data.publisher
            );
            const blob = new Blob([res.data]);
            const name = `AccountingReport_${data.dateFrom}_${data.dateTo}.${(allPublishers || data.publisher.length > 1) ? 'zip' : 'csv'}`;
            saveAs(blob, name);
        }, rejectWithValue);
    }
);

export const sendReportsToMail = createAsyncThunk(
    'accountingReport/sendReportsToMail',
    async (data: TransactionsFormData, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const allPublishers = data.publisher.some(publisher => publisher === -1);
            const accountingReportController = new AccountingReportControllerService();
            await accountingReportController.sendReportsToMail(
                data.dateFrom,
                data.dateTo,
                data.emails,
                allPublishers ? undefined : data.publisher,
            );
        }, rejectWithValue);
    }
);

export const accountingReportSlice = createSlice({
    name: 'accountingReport',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(isSlicePending, (state) => {
                state.pending = true;
            })
            .addMatcher(isSliceFinished, (state) => {
                state.pending = false;
            })
    }
});

export default accountingReportSlice.reducer;
