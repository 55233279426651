import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {EthereumPriceData} from '../../api/models/ethereum-price-data';
import ExchangeRateService from '../../api/services/exchange-rate.service';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';

interface BlockchainState {
    pending: boolean;
    isMetaMaskInstalled: boolean;
    walletId: string;
    error: string;
    balance?: number;
    chainId?: number;
    ethereumPriceData?: EthereumPriceData;
}

const initialState: BlockchainState = {
    pending: false,
    isMetaMaskInstalled: false,
    walletId: '',
    error: ''
};

export const getEthereumPriceData = createAsyncThunk(
    'blockchain/getEthereumPriceData',
    async (_, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const exchangeRateService = new ExchangeRateService();
            const exchangeRateResponse = await exchangeRateService.eurToEth();
            return exchangeRateResponse.data;
        }, rejectWithValue);
    }
);

export const blockchainSlice = createSlice({
    name: 'blockchain',
    initialState,
    reducers: {
        setIsMetaMaskInstalled: (state, action: PayloadAction<boolean>) => {
            state.isMetaMaskInstalled = action.payload;
        },
        blockchainInformationChangedPending: (state) => {
            state.pending = true;
            state.error = '';
        },
        blockchainInformationChangedSuccess: (state, action: PayloadAction<{ walletId: string; balance?: number, chainId?: number }>) => {
            state.walletId = action.payload.walletId;
            state.balance = action.payload.balance;
            state.error = '';
            state.pending = false;
            state.chainId = action.payload.chainId ?? state.chainId;
        },
        blockchainInformationChangedError: (state, action: PayloadAction<string>) => {
            state.walletId = '';
            state.balance = undefined;
            state.error = action.payload;
            state.pending = false;
        }
    },
    extraReducers: {
        [getEthereumPriceData.fulfilled.type]: (state, action: PayloadAction<EthereumPriceData>) => {
            state.pending = false;
            state.ethereumPriceData = action.payload;
        },
    }
});

export const {
    setIsMetaMaskInstalled,
    blockchainInformationChangedPending,
    blockchainInformationChangedSuccess,
    blockchainInformationChangedError
} = blockchainSlice.actions;
export default blockchainSlice.reducer;
