import {AxiosPromise} from 'axios';

import {backend} from '../../utils/_config/axios';
import {CreatokenBackendPayload} from '../models/creatoken-payload';
import {FullVariant} from '../models/full-variant';

export default class CreatokenControllerService {
    public buyCreatoken(data: CreatokenBackendPayload): AxiosPromise<{ contract: string }> {
        return backend().post('/7777/buy', data);
    }

    public getCreatokenVariants(): AxiosPromise<{variants: FullVariant[]}> {
        return backend().get('/7777/variants');
    }
}