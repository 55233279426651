import decodeJwt from 'jwt-decode';
import React, {useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';

import {fetchAccessTokenFromRefreshToken, RootState, useAppSelector} from '../../../redux';
import {initializeMetaMask} from '../../../redux/actions/blockchain.action';
import {clearUser, fetchUser} from '../../../redux/slices/user-slice';
import {JwtData} from '../../../utils/_models/jwt-data';
import Timeout = NodeJS.Timeout;

/**
 * An InitializeReduxWrapper React Component.
 * @author
 * @version 0.1
 */
export const InitializeReduxWrapper: React.FC = ({children}) => {
    const dispatch = useDispatch();
    const {loggedIn, jwt} = useAppSelector((state: RootState) => (state.authentification));
    const timeout = useRef<Timeout>();

    useEffect(() => {
        dispatch(fetchAccessTokenFromRefreshToken());
        dispatch(initializeMetaMask());
    }, []);

    useEffect(() => {
        if (jwt) {
            const jwtData = decodeJwt<JwtData>(jwt);
            const expirationInSec = jwtData.exp - jwtData.iat - 60;
            timeout.current = setTimeout(() => {
                dispatch(fetchAccessTokenFromRefreshToken());
            }, expirationInSec * 1000);
        } else {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        }
    }, [jwt]);


    useEffect(() => {
        if (loggedIn !== undefined) {
            loggedIn ? dispatch(fetchUser()) : dispatch(clearUser());
        }
    }, [loggedIn]);

    return <>{children}</>;
};
