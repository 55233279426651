import {AxiosPromise} from 'axios';

import {backend} from '../../utils/_config/axios';
import {AddWalletData} from '../models/add-wallet-data';
import {GetUnlockablesWithWalletData} from '../models/get-unlockables-with-wallet-data';
import {InitializeWalletResponse} from '../models/initialize-wallet-response';
import {VariantImage} from '../models/variant-image';

export default class WalletService {

    public initializeWalletConnection(): AxiosPromise<InitializeWalletResponse> {
        return backend(true).get('api/wallet/connect');
    }

    public addWallet(data: AddWalletData): AxiosPromise {
        return backend(true).post('api/wallet/connect', data);
    }

    public removeWallet(): AxiosPromise {
        return backend(true).get('api/wallet/disconnect');
    }

    public initializeGetUnlockablesWithWallet(): AxiosPromise<InitializeWalletResponse> {
        return backend().get('api/wallet/attachments');
    }

    public getUnlockablesWithWallet(data: GetUnlockablesWithWalletData): AxiosPromise<VariantImage[]> {
        return backend().post('api/wallet/attachments', data);
    }
}
