import {Action, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {InvoiceAddressData} from '../../api/models/invoice-address-data';
import InvoiceAddressControllerService from '../../api/services/invoice-address-controller.service';
import {ReduxStateModel} from '../../utils/_models/redux-state-model';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';

interface InvoiceAddressState {
    fetchInvoiceAddress: ReduxStateModel;
    updateInvoiceAddress: ReduxStateModel;
    formVisible: boolean;
    invoiceAddress?: InvoiceAddressData;
    pending: boolean;
    status: number | undefined;
}

const initialState: InvoiceAddressState = {
    fetchInvoiceAddress: {
        pending: false
    },
    updateInvoiceAddress: {
        pending: false
    },
    formVisible: false,
    pending: false,
    status: undefined
};

export const fetchInvoiceAddress = createAsyncThunk(
    'invoiceAddress/fetchInvoiceAddress',
    async (_, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const invoiceAddressController = new InvoiceAddressControllerService();
            const invoiceAddressResponse = await invoiceAddressController.fetchInvoiceAddress();
            return invoiceAddressResponse.data;
        }, rejectWithValue)
    }
);

export const updateInvoiceAddress = createAsyncThunk(
    'invoiceAddress/updateInvoiceAddress',
    async (formData: InvoiceAddressData, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const invoiceAddressController = new InvoiceAddressControllerService();
            let invoiceAddressResponse;
            if (formData.id === undefined) {
                invoiceAddressResponse = await invoiceAddressController.setInvoiceAddress(formData);
            } else {
                invoiceAddressResponse = await invoiceAddressController.updateInvoiceAddress(formData);
            }
            return invoiceAddressResponse.data;
        }, rejectWithValue);
    }
);

const isInvoiceAddressSlicePending = (action: Action) => {
    return action.type.startsWith('invoiceAddress') && action.type.endsWith('pending');
}

const isInvoiceAddressSliceRejected = (action: Action) => {
    return action.type.startsWith('invoiceAddress') && action.type.endsWith('rejected');
}

const isInvoiceAddressSliceFulfilled = (action: Action) => {
    return action.type.startsWith('invoiceAddress') && action.type.endsWith('fulfilled');
}

const invoiceAddressSlice = createSlice({
    name: 'invoiceAddress',
    initialState,
    reducers: {
        removeInvoiceAddressStatus: (state) => {
            state.updateInvoiceAddress.status = undefined;
            state.fetchInvoiceAddress.status = undefined;
        },
        setInvoiceAddressFormVisibility: (state, action: PayloadAction<boolean>) => {
            state.formVisible = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(updateInvoiceAddress.fulfilled, (state) => {
                state.updateInvoiceAddress.status = 200;
            })
            .addMatcher(
                isInvoiceAddressSlicePending,
                (state: InvoiceAddressState) => {
                    state.pending = true;
                }
            )
            .addMatcher(
                isInvoiceAddressSliceRejected,
                (state: InvoiceAddressState) => {
                    state.pending = false;
                }
            )
            .addMatcher(
                isInvoiceAddressSliceFulfilled,
                (state: InvoiceAddressState, action) => {
                    state.invoiceAddress = action.payload;
                    state.pending = false;
                }
            )
    }
});
export const {
    removeInvoiceAddressStatus,
    setInvoiceAddressFormVisibility
} = invoiceAddressSlice.actions;
export default invoiceAddressSlice.reducer;
