import {AxiosPromise} from 'axios';

import {backend} from '../../utils/_config/axios';
import {FilterItem} from '../../utils/_models/filter-item';
import {InstanceForm} from '../../utils/_models/instance-form';
import {DetailedInstance} from '../models/detailed-instance';
import {Instance} from '../models/instance';
import { InstanceMeta } from '../models/instanceMeta';
import {ResaleFixPrice} from '../models/resale-fix-price-data';

export default class InstancesService {

    public publishInstances(instance: InstanceForm): AxiosPromise<Instance> {
        return backend(true).post('api/sales-attributes', instance);
    }

    public getInstancesWithFilter(
        filterItems: FilterItem[]
    ): AxiosPromise<Instance[]> {
        const filterQuery = filterItems
            .map((filter, index) =>
                `${index === 0 ? '?' : '&'}${filter.param}${
                    filter.comparisonType ? '.' + filter.comparisonType : ''
                }=${filter.value}`)
            .join('');
        return backend().get(`api/instances${filterQuery}`);
    }

    public getDetailedInstancesWithFilter(
        filterItems: FilterItem[]
    ): AxiosPromise<DetailedInstance[]> {
        const filterQuery = filterItems
            .map((filter, index) =>
                `${index === 0 ? '?' : '&'}${filter.param}${
                    filter.comparisonType ? '.' + filter.comparisonType : ''
                }=${filter.value}`)
            .join('');
        return backend().get(`api/instances/detailed${filterQuery}`);
    }

    public getJsonMetaDataByMetaUrl(metaUrl: string): Promise<InstanceMeta> {
        return fetch(metaUrl).then(response => {
            if(!response.ok) return {
                name: 'missing meta',
                description: 'missing meta',
                external_url: 'missing meta',
                image: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==',
                attributes: []
            } as InstanceMeta

            return response.json() as unknown as InstanceMeta
        })
    }

    public getOwnedInstances(walletAddress: string): AxiosPromise<{
        not_minted: DetailedInstance[];
        minted: DetailedInstance[];
    }> {
        return backend(true).get(
            `api/instances/my${
                walletAddress ? `?address=${walletAddress}` : ''
            }`
        );
    }

    public getInstancesById(instanceId: number): AxiosPromise<Instance> {
        return backend(true).get(
            `api/instances/${instanceId}/full`
        )
    }

    public getElementsByIds(
        filterItems: FilterItem[]
    ): AxiosPromise<Instance[]> {
        const filterQuery = filterItems
            .map((filter, index) =>
                `${filter.param === 'id' ? filter.value : ''}${
                    index >= 2 && index < filterItems.length - 1 ? ',' : ''
                }`)
            .join('')
            const pageParam = filterItems.find((i) => i.param === 'size')?.value;
            const sizeParam = filterItems.find((i) => i.param === 'offset')?.value;
            const pageInfo = `&offset=${sizeParam}&size=${pageParam}`;
            const endPointUrl = `api/instances/detailed/list?id.in=${filterQuery}${pageInfo}`;
            return backend(true).get(endPointUrl);
    }

    public getInstancesCount(filterItems: FilterItem[]): AxiosPromise<number> {
        const filterQuery = filterItems
            .map((filter, index) =>
                `${index === 0 ? '?' : '&'}${filter.param}${
                    filter.comparisonType ? '.' + filter.comparisonType : ''
                }=${filter.value}`)
            .join('');
        return backend(true).get(`api/instances/count/${filterQuery}`);
    }

    public sellInstanceFixPrice(instanceId: number, data: ResaleFixPrice): AxiosPromise<Instance> {
        return backend(true)
            .put(`/api/instances/${instanceId}/resale`, data);
    }

    public deleteInstancesById(instanceId: number): AxiosPromise<{ instance: Instance }> {
        return backend(true).delete(
            `/api/sales-attributes/${instanceId}`
        );
    }

    public editInstancesById(instance: InstanceForm, instanceId: number): AxiosPromise<{ instance: Instance }> {
        return backend(true).put(
            `/api/sales-attributes/${instanceId}`, instance
        );
    }

    public stopResale(instanceId: number): AxiosPromise {
        return backend(true).delete(`/api/instances/${instanceId}/resale`);
    }
    
    public setTokenOnMint(instanceId: number): AxiosPromise {
        return backend(true).get(`/api/instances/${instanceId}/mint`);
    }

    public getMyOanInstances(userId: number): AxiosPromise<DetailedInstance[]> {
        return backend().get(`api/myoan/instances?userid=${userId}`);
    }
}
