import {AxiosPromise} from 'axios';

import {backend} from '../../utils/_config/axios';
import {FilterItem} from '../../utils/_models/filter-item';
import {Product} from '../models/product';

export default class ProductService {
    public createProduct(product: Product): AxiosPromise<Product> {
        return backend(true).post('api/products', product);
    }

    public getAllProducts(filterItems: FilterItem[]): AxiosPromise<Product[]> {
        const filterQuery = filterItems.map((filter, index) =>
            `${index === 0 ? '?' : '&'}${filter.param}${filter.comparisonType ? '.' + filter.comparisonType : ''}=${filter.value}`).join('')
        return backend().get(`api/products${filterQuery}`)
    }

    public getProductById(id: number): AxiosPromise<Product> {
        return backend(true).get('api/products/' + id);
    }

    public getProductByName(name: string): AxiosPromise<Product[]> {
        return backend(true).get('api/products?productName.equals=' + name);
    }

    public deleteProductById(id: number): AxiosPromise<Product> {
        return backend(true).delete('api/products/' + id);
    }

    public editProductById(product: Product): AxiosPromise<Product> {
        return backend(true).put('api/products/' + product.id, product);
    }
}
