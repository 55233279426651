import '../../public/index.css';
import '../themes/global.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';

import {config, library} from '@fortawesome/fontawesome-svg-core';
import {NextPage} from 'next';
import type {AppProps} from 'next/app';
import {NextDataHooksProvider} from 'next-data-hooks';
import {DefaultSeo} from 'next-seo';
import React from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Hydrate} from 'react-query/hydration';
import {Provider} from 'react-redux';
import {ToastProvider} from 'react-toast-notifications';

import {useCardText} from '../api/hooks/useCardText.hook';
import {useGlobal} from '../api/hooks/useGlobal.hook';
import GlobalToast from '../components/_shared/global-toast/global-toast';
import {Redirector} from '../components/_shared/redirector/redirector';
import {ToastNotification} from '../components/_shared/toast-notification/toast-notification';
import TopButton from '../components/_shared/top-button/top-button';
import {InitializeReduxWrapper} from '../components/app/initialize-redux-wrapper/initialize-redux-wrapper';
import {icons} from '../config/font-awesome-libary';
import {store} from '../redux';
import Layout from '../components/loading-bar/loading-bar';

library.add(...icons);
// https://fontawesome.com/docs/web/use-with/react/use-with
config.autoAddCss = false;

const MyApp: NextPage<AppProps> & { dataHooks: any } = ({Component, pageProps}) => {
    const [queryClient] = React.useState(() => new QueryClient());

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY}
        >
            <QueryClientProvider client={queryClient}>
                <ToastProvider
                    components={{Toast: ToastNotification}}
                    autoDismissTimeout={30000}
                    autoDismiss={true}
                    placement={'top-center'}
                >
                    <Hydrate state={pageProps.dehydratedState}>
                        <Provider store={store}>
                            <InitializeReduxWrapper>
                                <DefaultSeo
                                    defaultTitle="Creatokia"
                                    titleTemplate="%s - Creatokia"
                                    additionalLinkTags={[
                                        {
                                            href: '/favicon.svg',
                                            rel: 'icon',
                                            type: 'image/svg+xml'
                                        }
                                    ]}
                                />
                                <NextDataHooksProvider {...pageProps}>
                                    <GlobalToast/>
                                    <Redirector>
                                        {
                                            <Layout>
                                                <Component {...pageProps} />
                                                <TopButton/>
                                            </Layout>
                                        }
                                    </Redirector>
                                </NextDataHooksProvider>
                            </InitializeReduxWrapper>
                        </Provider>
                    </Hydrate>
                </ToastProvider>
            </QueryClientProvider>
        </GoogleReCaptchaProvider>
    );
};

MyApp.dataHooks = [
    useCardText,
    ...GlobalToast.dataHooks,
    useGlobal
];

export default MyApp;
