import { AxiosPromise } from 'axios';

import { backend } from '../../utils/_config/axios';
import { InvoiceAddressData } from '../models/invoice-address-data';

export default class InvoiceAddressControllerService {

    public fetchInvoiceAddress(): AxiosPromise<InvoiceAddressData> {
        return backend(true).get('/api/invoiceaddress');
    }

    public setInvoiceAddress(data: InvoiceAddressData): AxiosPromise<InvoiceAddressData> {
        return backend(true).post('/api/invoiceaddress', data);
    }

    public updateInvoiceAddress(data: InvoiceAddressData): AxiosPromise<InvoiceAddressData> {
        return backend(true).put('/api/invoiceaddress', data);
    }
}
