import {Contract} from 'ethers';
import resaleContractABI from '../../utils/_config/resale-contract-abi';
import {web3Instance} from '../../utils/_helper/window-instances';

export default class ResaleContractService {

    private contract: Contract;

    constructor() {
        const signer = web3Instance.getSigner();
        this.contract = new Contract((process.env.NEXT_PUBLIC_RESALE_CONTRACT as string), resaleContractABI, signer);
    }

    public buyAuction(tokenContractAddress: string,
                      tokenId: number,
                      nonce: string,
                      maxBlock: number,
                      sellerSignature: string,
                      creatokiaSignature: string): Promise<any> {
        return this.contract
            .buyAuction(tokenContractAddress, tokenId, nonce, maxBlock, sellerSignature, creatokiaSignature);
    }

    public buyFixPrice(tokenContractAddress: string,
                       tokenId: number,
                       nonce: string,
                       maxBlock: number,
                       priceInWei: string,
                       sellerSignature: string,
                       creatokiaSignature: string): Promise<any> {
        return this.contract
            .buyFixPrice(tokenContractAddress, tokenId, nonce, maxBlock, sellerSignature, creatokiaSignature,
                {value: priceInWei});
    }
}
