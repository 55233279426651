import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccountRoutePath } from '../../utils/_enums/route-path.enum';

interface AccountMenuState {
    selectedAccountRoute?: AccountRoutePath;
}

const initialState: AccountMenuState = {};

export const accountMenuSlice = createSlice({
    name: 'accountMenu',
    initialState,
    reducers: {
        setSelectedAccountMenuCategory: (
            state,
            action: PayloadAction<AccountRoutePath>
        ) => {
            state.selectedAccountRoute = action.payload;
        }
    }
});

export const { setSelectedAccountMenuCategory } = accountMenuSlice.actions;
export default accountMenuSlice.reducer;
