import {Action, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {BasketInstance} from '../../api/models/basket-instance';
import {ErrorResponse} from '../../api/models/error-response';
import BasketService from '../../api/services/basket-service';
import InitialDropContractService from '../../api/services/initial-drop-contract.service';
import PaypalService from '../../api/services/paypal-service';
import ResaleContractService from '../../api/services/resale-contract.service';
import {PaymentMethods} from '../../utils/_enums/payment-methods.enum';
import {ResponseCodes} from '../../utils/_enums/response-codes.enum';
import {SellType} from '../../utils/_enums/sell-type.enum';
import {ReduxStateModel} from '../../utils/_models/redux-state-model';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';

interface CheckoutState {
    pending: boolean;
    basket?: BasketInstance;
    maxTime?: number;
    showCheckoutConfirmation: boolean;
    paymentMethod?: PaymentMethods;
    sellType?: SellType;
    addToBasketStatus: number | undefined;
    fetchCurrentProductModelStatus: number | undefined;
    cancelBasketModel: ReduxStateModel;
    paypalMerchantIds: string[];
    fetchMerchantIdsError: boolean;

    // Stripe state variables
    success: boolean;
    stripeRedirectUrl?: string;
}

const initialState: CheckoutState = {
    pending: false,
    showCheckoutConfirmation: false,
    fetchCurrentProductModelStatus: undefined,
    addToBasketStatus: undefined,
    cancelBasketModel: {
        pending: false
    },
    paypalMerchantIds: [],
    fetchMerchantIdsError: false,

    success: false
};

export const addToBasket = createAsyncThunk(
    'checkout/addToBasket',
    async (data: { id: number; idType: 'INSTANCE' | 'VARIANT' }, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const basketService = new BasketService();
            let basketRes;
            if (data.idType === 'INSTANCE') {
                basketRes = await basketService.addInstanceToBasket(data.id);
            } else {
                basketRes = await basketService.addInstanceFromVariantToBasket(data.id);
            }
            return {basket: basketRes.data, maxTime: basketRes.headers['x-basket-timeout']};
        }, rejectWithValue);
    }
);

export const buyWithFiat = createAsyncThunk(
    'checkout/buyWithFiat',
    async (
        {
            instanceId,
            country,
            termsAndConditions,
            expirationRightOfWithdrawal,
        }: {
            instanceId: number;
            country: string;
            termsAndConditions: boolean;
            expirationRightOfWithdrawal: boolean;
        },
        {rejectWithValue}
    ) => {
        return await reduxThunkWrapper(async () => {
            const basketService = new BasketService();
            const checkoutRes = await basketService.fiatCheckout(
                instanceId,
                country,
                termsAndConditions,
                expirationRightOfWithdrawal
            );
            return checkoutRes.data.stripe_url;
        }, rejectWithValue);
    }
);

export const buyWithEth = createAsyncThunk(
    'checkout/buyWithEth',
    async (
        {
            instanceId,
            country,
            sellType,
            termsAndConditions,
            expirationRightOfWithdrawal
        }: {
            instanceId: number;
            country: string;
            sellType: SellType;
            termsAndConditions: boolean;
            expirationRightOfWithdrawal: boolean;
        },
        {rejectWithValue, dispatch}
    ) => {
        return await reduxThunkWrapper(async () => {
            const basketService = new BasketService();
            const checkoutRes = (
                await basketService.ethCheckout(instanceId, country, termsAndConditions, expirationRightOfWithdrawal)
            ).data;
            let tx;
            if (sellType === SellType.INITIAL_DROP) {
                const initialDropContract = new InitialDropContractService();
                tx = await initialDropContract.buyFixPrice(
                    checkoutRes.contractAddress,
                    checkoutRes.tokenid,
                    checkoutRes.tradeid,
                    checkoutRes.maxBlock,
                    checkoutRes.wei,
                    checkoutRes.sellerSignature
                );
            } else {
                const resaleContract = new ResaleContractService();
                tx = await resaleContract.buyFixPrice(
                    checkoutRes.contractAddress,
                    checkoutRes.tokenid,
                    checkoutRes.tradeid,
                    checkoutRes.maxBlock,
                    checkoutRes.wei,
                    checkoutRes.sellerSignature,
                    checkoutRes.signature
                );
            }
            dispatch(setShowCheckoutConfirmation(true));
            await tx.wait();
        }, rejectWithValue);
    }
);

export const fetchCurrentProduct = createAsyncThunk(
    'checkout/fetchCurrentProduct',
    async (_, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const basketService = new BasketService();
            const result = await basketService.fetchProductInCheckout();
            return {basket: result.data, maxTime: result.headers['x-basket-timeout']};
        }, rejectWithValue);
    }
);

export const fetchMerchantIds = createAsyncThunk(
    'checkout/fetchPaypalMerchantIds',
    async (variantId: number, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const paypalService = new PaypalService();
            const result = await paypalService.fetchMerchantIds(variantId);
            return result.data;
        }, rejectWithValue);
    }
);

export const cancelBasket = createAsyncThunk(
    'checkout/cancelBasket',
    async (_, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const basketService = new BasketService();
            await basketService.cancelBasket();
        }, rejectWithValue)
    }
);

const isCheckoutSlicePending = (action: Action) => {
    return action.type.startsWith('checkout') && action.type.endsWith('pending');
}

const isCheckoutSliceFinished = (action: Action) => {
    return action.type.startsWith('checkout') &&
        (action.type.endsWith('rejected') || action.type.endsWith('fulfilled'));
}

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setShowCheckoutConfirmation: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.showCheckoutConfirmation = action.payload;
        },
        setPaymentMethod: (
            state,
            action: PayloadAction<PaymentMethods | undefined>
        ) => {
            state.paymentMethod = action.payload;
        },
        resetCheckoutProcess: (state) => {
            state.paymentMethod = undefined;
            state.basket = undefined;
            state.cancelBasketModel.status = undefined;
            state.paypalMerchantIds = [];
            state.fetchMerchantIdsError = false;
        },
        resetFetchCurrentProductState: (state) => {
            state.fetchCurrentProductModelStatus = undefined;
        },
        resetAddToBasketState: (state) => {
            state.addToBasketStatus = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addToBasket.pending, (state) => {
                state.basket = undefined;
                state.maxTime = undefined;
                state.sellType = undefined;
                state.pending = true;
                state.addToBasketStatus = undefined;
            })
            .addCase(addToBasket.fulfilled, (state, action) => {
                state.basket = action.payload.basket;
                state.maxTime = action.payload.maxTime;
                state.sellType = action.payload.basket.instance.ownerId || action.payload.basket.instance.ownerWallet
                    ? SellType.RESALE
                    : SellType.INITIAL_DROP;
                state.addToBasketStatus = ResponseCodes.OK;
                state.pending = false;
            })
            .addCase(addToBasket.rejected, (
                state,
                action
            ) => {
                state.addToBasketStatus = (action.payload as ErrorResponse).data.code;
                state.pending = false;
            })
            .addCase(cancelBasket.fulfilled, (state) => {
                state.cancelBasketModel.status = 200;
                state.cancelBasketModel.pending = false;
            })
            .addCase(fetchMerchantIds.fulfilled, (state, action: PayloadAction<string[]>) => {
                state.paypalMerchantIds = action.payload;
            })
            .addCase(fetchMerchantIds.rejected, (state) => {
                state.fetchMerchantIdsError = true;
            })
            .addCase(fetchCurrentProduct.fulfilled, (
                state,
                action: PayloadAction<{ basket: BasketInstance; maxTime: number }>
            ) => {
                state.fetchCurrentProductModelStatus = 200;
                state.basket = action.payload.basket;
                state.maxTime = action.payload.maxTime;
                state.sellType = action.payload.basket.instance.ownerId || action.payload.basket.instance.ownerWallet
                    ? SellType.RESALE
                    : SellType.INITIAL_DROP;
            })
            .addCase(buyWithFiat.fulfilled,(
                state,
                action: PayloadAction<string>
            ) => {
                state.success = true;
                state.stripeRedirectUrl = action.payload;
            })
            .addCase(fetchCurrentProduct.rejected, (
                state,
                action
            ) => {
                state.fetchCurrentProductModelStatus = (action.payload as ErrorResponse).status;
            })
            .addMatcher(
                isCheckoutSlicePending,
                (state: CheckoutState) => {
                    state.addToBasketStatus = undefined;
                    state.pending = true;
                }
            )
            .addMatcher(
                isCheckoutSliceFinished,
                (state: CheckoutState) => {
                    state.pending = false;
                }
            )
    }
});

export const {
    setShowCheckoutConfirmation,
    setPaymentMethod,
    resetFetchCurrentProductState,
    resetCheckoutProcess,
    resetAddToBasketState
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
