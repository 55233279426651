import { AxiosPromise } from 'axios';

import { backend } from '../../utils/_config/axios';
import { ContactFormData } from '../models/contact-form-data';

export default class SupportService {
    public sendSupportMail(
        data: ContactFormData
    ): AxiosPromise<{ valid: boolean }> {
        return backend(true).post('/support', data);
    }
}
