import {AxiosPromise} from 'axios';

import {backend} from '../../utils/_config/axios';
import {BasketInstance} from '../models/basket-instance';
import {EthFixPrice} from '../models/eth-fix-price';

export default class BasketService {
    public addInstanceToBasket(
        instanceId: number
    ): AxiosPromise<BasketInstance> {
        return backend(true).post('api/baskets', {
            instance: {id: instanceId}
        });
    }

    public addInstanceFromVariantToBasket(
        variantId: number
    ): AxiosPromise<BasketInstance> {
        return backend(true).post('api/baskets', {
            instance: {variantId: variantId}
        });
    }

    public fiatCheckout(
        instanceId: number,
        country: string,
        termsAndConditions: boolean,
        expirationRightOfWithdrawal: boolean
    ): AxiosPromise<{ stripe_url: string }> {
        return backend(true)
            .post('/api/checkout', {
                productId: instanceId,
                typeOfPurchase: 'FIAT',
                country: country,
                termsAndConditions: termsAndConditions,
                expirationRightOfWithdrawal: expirationRightOfWithdrawal
            });
    }

    /**
     * Fiat Checkout with PayPal
     * @param instanceId
     * @param country
     * @param termsAndConditions
     * @param expirationRightOfWithdrawal
     */
    // public fiatCheckout(
    //     instanceId: number,
    //     country: string,
    //     termsAndConditions: boolean,
    //     expirationRightOfWithdrawal: boolean
    // ): Promise<string> {
    //     return backend(true)
    //         .post('/api/checkout', {
    //             productId: instanceId,
    //             typeOfPurchase: 'FIAT',
    //             country: country,
    //             termsAndConditions: termsAndConditions,
    //             expirationRightOfWithdrawal: expirationRightOfWithdrawal
    //         })
    //         .then(value => value.data.order_id);
    // }

    public ethCheckout(
        instanceId: number,
        country: string,
        termsAndConditions: boolean,
        expirationRightOfWithdrawal: boolean
    ): AxiosPromise<EthFixPrice> {
        return backend(true).post('/api/checkout', {
            productId: instanceId,
            typeOfPurchase: 'ETH',
            country: country,
            termsAndConditions: termsAndConditions,
            expirationRightOfWithdrawal: expirationRightOfWithdrawal
        });
    }

    public fetchProductInCheckout(): AxiosPromise<BasketInstance> {
        return backend(true).get('/api/baskets/currentUser');
    }

    public cancelBasket(): AxiosPromise {
        return backend(true).get('/api/baskets/cancel');
    }
}
