import {Web3Provider} from '@ethersproject/providers';
import {Dispatch} from '@reduxjs/toolkit';
import {utils} from 'ethers';

import {ethereumInstance, setWeb3Instance, web3Instance} from '../../utils/_helper/window-instances';
import {
    blockchainInformationChangedError,
    blockchainInformationChangedPending,
    blockchainInformationChangedSuccess,
    setIsMetaMaskInstalled
} from '../slices/blockchain-slice';

const initializeMetaMask = () => async (dispatch: Dispatch): Promise<void> => {
    if (ethereumInstance) {
        ethereumInstance.on(
            'accountsChanged',
            async (accounts: string[]) => {
                if (accounts.length > 0) {
                    const balance = await getBalance(ethereumInstance.selectedAddress);
                    dispatch(
                        blockchainInformationChangedSuccess({
                            walletId: accounts[0],
                            balance,

                        })
                    );
                } else {
                    dispatch(
                        blockchainInformationChangedSuccess({
                            walletId: '',
                            balance: undefined
                        })
                    );
                }
            }
        );
        ethereumInstance.on('chainChanged', () => {
            window.location.reload();
        });
        try {
            dispatch(setIsMetaMaskInstalled(true));
            const web3 = new Web3Provider(ethereumInstance);
            setWeb3Instance(web3);
            if (ethereumInstance.selectedAddress) {
                const balance = await getBalance(ethereumInstance.selectedAddress);
                const chainId = await ethereumInstance.request({method: 'eth_chainId'});
                dispatch(
                    blockchainInformationChangedSuccess({
                        walletId: ethereumInstance.selectedAddress,
                        balance,
                        chainId: parseInt(chainId.toString(), 16)
                    })
                );
            }
        } catch (error) {
            dispatch(blockchainInformationChangedError(error.message));
        }
    } else {
        dispatch(setIsMetaMaskInstalled(false));
    }
};

const connectToMetaMask = () => async (dispatch: Dispatch): Promise<void> => {
    try {
        dispatch(blockchainInformationChangedPending());
        const web3 = new Web3Provider(ethereumInstance);
        setWeb3Instance(web3);
        const accounts = await ethereumInstance.request({method: 'eth_requestAccounts'});
        const chainId = await ethereumInstance.request({method: 'eth_chainId'});
        const balance = await getBalance(accounts[0]);
        dispatch(
            blockchainInformationChangedSuccess({
                walletId: accounts[0],
                balance,
                chainId: parseInt(chainId.toString(), 16)
            })
        );
    } catch (error) {
        dispatch(blockchainInformationChangedError(error.message));
    }
};

const getBalance = async (walletId: string): Promise<number | undefined> => {
    try {
        const wei = await web3Instance.getBalance(walletId);
        return Number(utils.formatEther(wei));
    } catch (e) {
        return undefined;
    }
};

export {connectToMetaMask, initializeMetaMask};
