import { StrapiButtonProps } from '../models/strapi/strapi-button-props';
import { StrapiHeadline } from '../models/strapi/strapi-headline';
import { StrapiParagraphProps } from '../models/strapi/strapi-paragraph-props';
import { useLanguageDependentDataSSr } from './useLanguageDependentDataSsr.hook';

export const useGlobal = useLanguageDependentDataSSr<UseGlobalData>('global');

interface UseGlobalData {
    dialogs: {
        loginDialogHeadline: StrapiHeadline;
        loginDialogDescription: StrapiParagraphProps;
        connectWalletHeadline: StrapiHeadline;
        connectWalletDescription: StrapiParagraphProps;
        allowListNotAllowedHeadline: StrapiHeadline;
        maxInstancesBought: StrapiParagraphProps;
        maxInstancesBoughtHeadline: StrapiHeadline;
        allowListNotAllowed: StrapiParagraphProps;
    };
    buttons: {
        connectMetamask: StrapiButtonProps;
        signMessage: StrapiButtonProps;
        download: StrapiButtonProps;
        confirmation: StrapiButtonProps;
    };
    errorMessages: {
        checkoutError: string;
        emptyBasket: string;
        updatedSuccessfully: string;
        updatedError: string;
        copiedSuccessfully: string;
    };
    brand: {
        creatokia: string;
    };
    salesBox: {
        valueAddedTax: string;
        price: string;
        transactionFee: string;
        initialBuy: string;
        availableIn: string;
        remainingTimeToBuy: string;
        feesHeadline: string;
        feesDescription: string;
        availableFrom: string;
        availableUntil: string;
    };
    urls: {
        creatokenUrl: string;
    }
}
