import {
    faFacebookF as fabFacebookF,
    faInstagram as fabInstagram,
    faTelegramPlane as fabTelegramPlane,
    faTwitter as fabTwitter} from '@fortawesome/free-brands-svg-icons';
import {
    faCalendarMinus as farCalendarMinus,
    faCopy as farCopy,
    faEdit as farEdit,
    faEnvelope as farEnvelope,
    faIdCard as farIdCard,
    faPlayCircle as farPlayCircle,
    faTrashAlt as farTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {
    faBars as fasBars,
    faClock as fasClock,
    faDownload as fasDownload,
    faFilter as fasFilter,
    faHighlighter as fasHighlighter,
    faInfo as fasInfo,
    faMicrophone as faMicrophone,
    faMountain as fasMountain,
    faPaperPlane as fasPaperPlane,
    faPlay as fasPlay,
    faShoppingCart as fasShoppingCart,
    faStepBackward as fasStepBackward,
    faStepForward as fasStepForward,
    faTimes as fasTimes,
    faUndo as fasUndo,
    faChevronRight as fasChevronRight,
    faPlus as fasPlus,
    faTrash as fasTrash,
    faCheck as fasCheck,
    faPen as fasPen,
    IconDefinition,
    IconPack
} from '@fortawesome/free-solid-svg-icons';
import {
    faBars as fadBars,
    faBook as fadBook,
    faClock as fadClock,
    faDownload as fadDownload,
    faInfo as fadInfo,
    faMountain as fadMountain,
    faPencil as fadPencil,
    faPlay as fadPlay,
    faStepBackward as fadStepBackward,
    faStepForward as fadStepForward,
    faTimes as fadTimes,
    faUser as fadUser} from '@fortawesome/pro-duotone-svg-icons';
import {
    faBars as falBars,
    faBook as falBook,
    faClock as falClock,
    faDownload as falDownload,
    faInfo as falInfo,
    faMountain as falMountain,
    faPencil as falPencil,
    faPlay as falPlay,
    faStepBackward as falStepBackward,
    faStepForward as falStepForward,
    faTimes as falTimes,
    faUser as falUser} from '@fortawesome/pro-light-svg-icons';
import {
    faBars as farBars,
    faBook as farBook,
    faBookOpen as farBookOpen,
    faCartPlus as farCartPlus,
    faCheck as farCheck,
    faChevronDown as farChevronDown,
    faChevronUp as farChevronUp,
    faClock as faClock,
    faDownload as farDownload,
    faExternalLinkSquareAlt as farExternalLinkSquareAlt,
    faExternalLink as farExternalLink,
    faHeadphones as farHeadphones,
    faInfo as farInfo,
    faLongArrowAltLeft as farLongArrowAltLeft,
    faLongArrowAltRight as farLongArrowAltRight,
    faMountain as farMountain,
    faPalette as farPalette,
    faPen as farPen,
    faPencil as farPencil,
    faPlay as farPlay,
    faStepBackward as farStepBackward,
    faStepForward as farStepForward,
    faTimes as farTimes,
    faUser as farUser} from '@fortawesome/pro-regular-svg-icons';
import {
    faPencil as fasPencil,
    faUser as fasUser
} from '@fortawesome/pro-solid-svg-icons';

export const icons: (IconDefinition | IconPack)[] = [
    farUser,
    farPen,
    fasUser,
    falUser,
    fadUser,
    farCartPlus,
    farPlayCircle,
    farBookOpen,
    farHeadphones,
    fasHighlighter,
    farPalette,
    fasMountain,
    fadUser,
    fadMountain,
    farMountain,
    fasFilter,
    falMountain,
    farExternalLinkSquareAlt,
    farExternalLink,
    farEdit,
    farIdCard,
    farEnvelope,
    fasPaperPlane,
    fasTimes,
    fadTimes,
    falTimes,
    farTimes,
    farCalendarMinus,
    fasClock,
    fadClock,
    falClock,
    faClock,
    farChevronDown,
    farCheck,
    fasBars,
    fadBars,
    farBars,
    falBars,
    farLongArrowAltRight,
    farLongArrowAltLeft,
    farPencil,
    fadPencil,
    falPencil,
    fasPencil,
    farBook,
    fadBook,
    falBook,
    fasPlay,
    falPlay,
    farPlay,
    fadPlay,
    farTrashAlt,
    fasPlay,
    falPlay,
    farPlay,
    fadPlay,
    fasStepBackward,
    falStepBackward,
    fadStepBackward,
    farStepBackward,
    fasStepForward,
    farStepForward,
    fadStepForward,
    falStepForward,
    fasInfo,
    fadInfo,
    falInfo,
    farInfo,
    fasDownload,
    falDownload,
    farDownload,
    fadDownload,
    fabTwitter,
    fabInstagram,
    fabFacebookF,
    fabTelegramPlane,
    farCopy,
    faMicrophone,
    fasShoppingCart,
    farChevronUp,
    fasUndo,
    fasChevronRight,
    fasPlus,
    fasTrash,
    fasPen,
    fasCheck
];
