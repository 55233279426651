import { AxiosPromise } from 'axios';

import { backend } from '../../utils/_config/axios';
import { ChangePasswordData } from '../models/change-password-data';
import { UpdateProfileData } from '../models/profile-data';
import { User } from '../models/user';
import { UserRole } from '../models/user-role';

export default class UsersService {
    public fetchUser(): AxiosPromise<User> {
        return backend(true).get('/users/');
    }

    public updateUser(data: User): AxiosPromise<User> {
        return backend(true).put('/users/', data);
    }

    public changePassword(
        data: ChangePasswordData
    ): AxiosPromise<{ valid: boolean }> {
        return backend(true).put('/users/change-password', data);
    }

    public updateUserProfileData(
        userId: number,
        data: UpdateProfileData
    ): AxiosPromise<{ valid: boolean }> {
        return backend(true).put(`/users/${userId}`, data);
    }

    public getUserById(id: number): AxiosPromise<User> {
        return backend(true).get(`/users/${id}`);
    }


    public getUsersByRole(userRole: UserRole): AxiosPromise<User[]> {
        return backend(true).get<User[]>(`/api/strapi-users?role=${userRole}`);
    }

    public getPublicUserById(id: number): AxiosPromise<User[]> {
        return backend(true).get<User[]>(`/api/strapi-users/public?id.equals=${id}`);
    }

    public getMyOanUser(username: string): AxiosPromise<User[]> {
        return backend(true).get<User[]>(`/api/strapi-users/my-oan?username.equals=${username}`);
    }

    public searchPublisherByName(name: string, byId?: boolean): AxiosPromise<User[]> {
        return backend(true).get<User[]>(`/api/strapi-users/searchPublisher?${byId ? 'id.equals=' : 'name='}${name}`);
    }

    public getPublishersWithWallet(): AxiosPromise<User[]> {
        return backend(true).get<User[]>(`/api/strapi-users/publishersWithWallets`);
    }

    public getOriginatorsWithWallet(): AxiosPromise<User[]> {
        return backend(true).get<User[]>(`/api/strapi-users/originatorsWithWallets`);
    }

    public searchOriginatorsByName(name: string): AxiosPromise<User[]> {
        return backend().get<User[]>(`/api/strapi-users/searchOriginator?name=${name}`);
    }

    public updateProfilePicture(formData: FormData): AxiosPromise {
        return backend(true).post('/api/user-profile/image', formData);
    }
}
