import {ContractInterface} from 'ethers';

const initialDropContractABI: ContractInterface = [
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'configContract',
                'type': 'address'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'constructor'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'previousOwner',
                'type': 'address'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'newOwner',
                'type': 'address'
            }
        ],
        'name': 'OwnershipTransferred',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': false,
                'internalType': 'address',
                'name': 'tokenContract',
                'type': 'address'
            },
            {
                'indexed': false,
                'internalType': 'address',
                'name': 'account',
                'type': 'address'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': 'shares',
                'type': 'uint256'
            }
        ],
        'name': 'PayeeAdded',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': false,
                'internalType': 'address',
                'name': 'tokenContract',
                'type': 'address'
            },
            {
                'indexed': false,
                'internalType': 'address',
                'name': 'to',
                'type': 'address'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': 'amount',
                'type': 'uint256'
            }
        ],
        'name': 'PaymentReleased',
        'type': 'event'
    },
    {
        'inputs': [],
        'name': 'owner',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'renounceOwnership',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'newOwner',
                'type': 'address'
            }
        ],
        'name': 'transferOwnership',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'configContract',
                'type': 'address'
            }
        ],
        'name': 'setConfigContract',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'tokenContract',
                'type': 'address'
            },
            {
                'internalType': 'uint256',
                'name': 'tokenId',
                'type': 'uint256'
            },
            {
                'internalType': 'string',
                'name': 'nonce',
                'type': 'string'
            },
            {
                'internalType': 'uint256',
                'name': 'maxBlock',
                'type': 'uint256'
            },
            {
                'internalType': 'bytes',
                'name': 'signature',
                'type': 'bytes'
            }
        ],
        'name': 'buyFixPrice',
        'outputs': [],
        'stateMutability': 'payable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'tokenContract',
                'type': 'address'
            }
        ],
        'name': 'release',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'tokenContract',
                'type': 'address'
            },
            {
                'internalType': 'address',
                'name': 'account',
                'type': 'address'
            }
        ],
        'name': 'shares',
        'outputs': [
            {
                'internalType': 'uint16',
                'name': '',
                'type': 'uint16'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'tokenContract',
                'type': 'address'
            },
            {
                'internalType': 'uint256',
                'name': 'index',
                'type': 'uint256'
            }
        ],
        'name': 'payee',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'tokenContract',
                'type': 'address'
            },
            {
                'internalType': 'address[]',
                'name': 'payees',
                'type': 'address[]'
            },
            {
                'internalType': 'uint16[]',
                'name': 'shares_',
                'type': 'uint16[]'
            }
        ],
        'name': 'setPayees',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    }
]

export default initialDropContractABI;