import {AxiosPromise} from 'axios';

import {backend} from '../../utils/_config/axios';
import {EthereumPriceData} from '../models/ethereum-price-data';

export default class ExchangeRateService {

    public eurToEth(): AxiosPromise<EthereumPriceData> {
        return backend(true).get('api/exchange-rate/eur-to-eth');
    }
}
