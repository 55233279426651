import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {NewsletterRequest} from '../../api/models/newsletter-request';
import {SubscriptionData} from '../../api/models/subscription-data';
import {NewsletterService} from '../../api/services/newsletter-service';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';

interface LetterModel {
    pending: boolean;
    submitted: boolean;
    subscriptionStatus?: SubscriptionData;
}

interface NewsletterState {
    newsletter: LetterModel,
    dropletter: LetterModel,
}

const initialState: NewsletterState = {
    newsletter: {
        pending: false,
        submitted: false
    },
    dropletter: {
        pending: false,
        submitted: false
    }
};

export const sendNewsletterSubscription = createAsyncThunk(
    'newsletter/subscribe',
    async (data: NewsletterRequest, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const newsletterService = new NewsletterService();
            const newsletterResponse = await newsletterService.subscribeToNewsletter(
                data
            );
            return newsletterResponse.data;
        }, rejectWithValue);
    }
);

export const userUnsubscribeFromNewsletter = createAsyncThunk(
    'newsletter/unsubscribe',
    async (language: string, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const newsletterService = new NewsletterService();
            const newsletterResponse = await newsletterService.userUnsubscribeFromNewsletter(language);
            return newsletterResponse.data;
        }, rejectWithValue);
    }
);

export const userSubscribeToNewsletter = createAsyncThunk(
    'newsletter/subscribe',
    async (language: string, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const newsletterService = new NewsletterService();
            const newsletterResponse = await newsletterService.userSubscribeToNewsletter(language);
            return newsletterResponse.data;
        }, rejectWithValue);
    }
);


export const checkStatusOfNewsletterSubscription = createAsyncThunk(
    'newsletter/subscribe/checkStatus',
    async (language: string, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const newsletterService = new NewsletterService();
            const newsletterResponse = await newsletterService.checkStatusOfSubscription(language);
            return newsletterResponse.data;
        }, rejectWithValue);
    }
);

export const userUnsubscribeFromDropletter = createAsyncThunk(
    'newsletter/unsubscribeDropletter',
    async (language: string, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const newsletterService = new NewsletterService();
            const newsletterResponse = await newsletterService.userUnsubscribeFromNewsletter(language, false);
            return newsletterResponse.data;
        }, rejectWithValue);
    }
);

export const userSubscribeToDropletter = createAsyncThunk(
    'newsletter/subscribeDropletter',
    async (language: string, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const newsletterService = new NewsletterService();
            const newsletterResponse = await newsletterService.userSubscribeToNewsletter(language, false);
            return newsletterResponse.data;
        }, rejectWithValue);
    }
);


export const checkStatusOfDropletterSubscription = createAsyncThunk(
    'newsletter/subscribe/checkStatusDropletter',
    async (language: string, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const newsletterService = new NewsletterService();
            const newsletterResponse = await newsletterService.checkStatusOfSubscription(language, false);
            return newsletterResponse.data;
        }, rejectWithValue);
    }
);

export const newsletterSlice = createSlice({
    name: 'newsletter',
    initialState,
    reducers: {
        resetNewsletterSubscription: (state) => {
            state.newsletter.submitted = false;
            state.newsletter.subscriptionStatus = undefined;
        }
    },
    extraReducers: {
        [sendNewsletterSubscription.pending.type]: (state) => {
            state.newsletter.pending = true;
            state.newsletter.submitted = false;
        },
        [sendNewsletterSubscription.fulfilled.type]: (state) => {
            state.newsletter.pending = false;
            state.newsletter.submitted = true;
        },
        [sendNewsletterSubscription.rejected.type]: (
            state
        ) => {
            state.newsletter.submitted = false;
            state.newsletter.pending = false;
        },
        [userUnsubscribeFromNewsletter.pending.type]: (state) => {
            state.newsletter.pending = true;
            state.newsletter.submitted = false;
        },
        [userUnsubscribeFromNewsletter.fulfilled.type]: (
            state,
            action: PayloadAction<SubscriptionData>
        ) => {
            state.newsletter.pending = false;
            state.newsletter.submitted = true;
            state.newsletter.subscriptionStatus = action.payload;
        },
        [userUnsubscribeFromNewsletter.rejected.type]: (
            state
        ) => {
            state.newsletter.submitted = false;
            state.newsletter.pending = false;
        },
        [userSubscribeToNewsletter.pending.type]: (state) => {
            state.newsletter.pending = true;
            state.newsletter.submitted = false;
        },
        [userSubscribeToNewsletter.fulfilled.type]: (
            state,
            action: PayloadAction<SubscriptionData>
        ) => {
            state.newsletter.pending = false;
            state.newsletter.submitted = true;
            state.newsletter.subscriptionStatus = action.payload;
        },
        [userSubscribeToNewsletter.rejected.type]: (
            state
        ) => {
            state.newsletter.submitted = false;
            state.newsletter.pending = false;
        },
        [checkStatusOfNewsletterSubscription.pending.type]: (state) => {
            state.newsletter.pending = true;
            state.newsletter.subscriptionStatus = undefined;
        },
        [checkStatusOfNewsletterSubscription.fulfilled.type]: (
            state,
            action: PayloadAction<SubscriptionData>
        ) => {
            state.newsletter.pending = false;
            state.newsletter.subscriptionStatus = action.payload;
        },
        [checkStatusOfNewsletterSubscription.rejected.type]: (
            state
        ) => {
            state.newsletter.pending = false;
        },
        [userUnsubscribeFromDropletter.pending.type]: (state) => {
            state.dropletter.pending = true;
            state.dropletter.submitted = false;
        },
        [userUnsubscribeFromDropletter.fulfilled.type]: (
            state,
            action: PayloadAction<SubscriptionData>
        ) => {
            state.dropletter.pending = false;
            state.dropletter.submitted = true;
            state.dropletter.subscriptionStatus = action.payload;
        },
        [userUnsubscribeFromDropletter.rejected.type]: (
            state
        ) => {
            state.dropletter.submitted = false;
            state.dropletter.pending = false;
        },
        [userSubscribeToDropletter.pending.type]: (state) => {
            state.dropletter.pending = true;
            state.dropletter.submitted = false;
        },
        [userSubscribeToDropletter.fulfilled.type]: (
            state,
            action: PayloadAction<SubscriptionData>
        ) => {
            state.dropletter.pending = false;
            state.dropletter.submitted = true;
            state.dropletter.subscriptionStatus = action.payload;
        },
        [userSubscribeToDropletter.rejected.type]: (
            state
        ) => {
            state.dropletter.submitted = false;
            state.dropletter.pending = false;
        },
        [checkStatusOfDropletterSubscription.pending.type]: (state) => {
            state.dropletter.pending = true;
            state.dropletter.subscriptionStatus = undefined;
        },
        [checkStatusOfDropletterSubscription.fulfilled.type]: (
            state,
            action: PayloadAction<SubscriptionData>
        ) => {
            state.dropletter.pending = false;
            state.dropletter.subscriptionStatus = action.payload;
        },
        [checkStatusOfDropletterSubscription.rejected.type]: (
            state
        ) => {
            state.dropletter.pending = false;
        }
    }
});

export const {resetNewsletterSubscription} = newsletterSlice.actions;
export default newsletterSlice.reducer;
