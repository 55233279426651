import axios, { AxiosPromise } from 'axios';
import { store } from '../../redux';
import { backend } from '../../utils/_config/axios';
import ImageSize from '../../utils/_enums/image-size.enum';
import { FilterItem } from '../../utils/_models/filter-item';
import { FullVariant } from '../models/full-variant';
import { Variant } from '../models/variant';
import { VariantImage } from '../models/variant-image';
import { VariantImageType } from '../models/variant-image-type';

export default class VariantService {
    public getVariantsByProductId(productId: number): AxiosPromise<Variant[]> {
        return backend(true).get('api/variants/?productId.equals=' + productId);
    }

    public getVariantById(variantId: number): AxiosPromise<Variant[]> {
        return backend(true).get('api/variants/' + variantId);
    }

    public deleteVariantById(variantId: number): AxiosPromise<Variant[]> {
        return backend(true).delete('api/variants/' + variantId);
    }

    public addFile(file: VariantImage): AxiosPromise<VariantImage> {
        const formData = new FormData();
        formData.append('variantId', file.variantId.toString());
        formData.append('file', file.content);
        formData.append('description', file.description ?? '');
        formData.append('name', file.name ?? '');
        formData.append('type', file.type);

        return backend(true).post('/api/perm-attachments', formData);
    }

    public getVariants(filterItems: FilterItem[]): AxiosPromise<Variant[]> {
        const filterQuery = filterItems
            .map(
                (filter, index) =>
                    `${index === 0 ? '?' : '&'}${filter.param}${
                        filter.comparisonType ? '.' + filter.comparisonType : ''
                    }=${filter.value}`
            )
            .join('');
        return backend().get(`api/variants${filterQuery}`);
    }

    public getFullVariant(id: number): AxiosPromise<FullVariant> {
        return backend().get(`api/variants/${id}/full`);
    }

    public getDetailedVariants(
        filterItems: FilterItem[]
    ): AxiosPromise<Variant[]> {
        const filterQuery = filterItems
            .map(
                (filter, index) =>
                    `${index === 0 ? '?' : '&'}${filter.param}${
                        filter.comparisonType ? '.' + filter.comparisonType : ''
                    }=${filter.value}`
            )
            .join('');
        const endPointUrl = `api/variants/detailed${filterQuery}`;
        return backend().get(endPointUrl);
    }

    public getElementsByIds(
        filterItems: FilterItem[]
    ): AxiosPromise<Variant[]> {
        const filterQuery = filterItems
            .map(
                (filter, index) =>
                    `${filter.param === 'id' ? filter.value : ''}${
                        index >= 2 && index < filterItems.length - 1 ? ',' : ''
                    }`
            )
            .join('');
        const pageParam = filterItems.find((i) => i.param === 'size')?.value;
        const sizeParam = filterItems.find((i) => i.param === 'offset')?.value;
        const pageInfo = `&offset=${sizeParam}&size=${pageParam}`;
        const endPointUrl = `api/variants/detailed/list?id.in=${filterQuery}${pageInfo}`;
        return backend(true).get(endPointUrl);
    }

    public getRandomVariants(
        filterItems: FilterItem[]
    ): AxiosPromise<Variant[]> {
        const filterQuery = filterItems
            .map(
                (filter, index) =>
                    `${index === 0 ? '?' : '&'}${filter.param}${
                        filter.comparisonType ? '.' + filter.comparisonType : ''
                    }=${filter.value}`
            )
            .join('');
        return backend().get(`api/variants/rand${filterQuery}`);
    }

    public getReleasedVariantsWithProducts(
        pageNumber: number,
        pageSize: number
    ): AxiosPromise<Variant[]> {
        return backend(true).get(
            'api/variants/?publishedAt.specified=true&page=' +
                pageNumber +
                '&size=' +
                pageSize
        );
    }

    public editVariant(variant: Variant, id: number): AxiosPromise<Variant> {
        return backend(true).put('/api/variants/' + id, variant);
    }

    public addVariant(variant: Variant): AxiosPromise<Variant> {
        return backend(true).post('/api/variants', variant);
    }

    public getPermAttachments(
        variantId: number,
        type?: VariantImageType
    ): AxiosPromise<VariantImage[]> {
        return backend(true).get(
            `api/perm-attachments/?variantId.equals=${variantId}${
                type ? `&type.equals=${type}` : ''
            }`
        );
    }

    public getPermAttachmentContent(
        id: number,
        baseURL: string,
        isAdmin?: boolean,
        imageSize: ImageSize = ImageSize.ORIGINAL
    ): AxiosPromise<string> {
        return axios
            .create({
                baseURL: baseURL,
                withCredentials: true,
                headers: {
                    common: {
                        Authorization: `Bearer ${
                            store.getState().authentification.jwt
                        }`
                    }
                },
                responseType: 'arraybuffer'
            })
            .get(
                `api/perm-attachments/${id}${
                    isAdmin ? '/admin' : ''
                }/content?resolution=${imageSize}`
            );
    }

    public getPermAttachment(id: number): AxiosPromise<VariantImage> {
        return backend(true).get(`api/perm-attachments/${id}`);
    }

    public deletePermAttachments(fileId: number): AxiosPromise<void> {
        return backend(true).delete(`api/perm-attachments/${fileId}`);
    }
}
