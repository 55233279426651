export const isValidGeoIp = (country: string): boolean => {
    return ['de', 'be', 'bg', 'dk', 'ee', 'fi', 'fr', 'gr', 'ie', 'it',
        'hr', 'lv', 'lt', 'lu', 'mt', 'nl', 'at', 'pl', 'pt', 'ro',
        'se', 'sk', 'si', 'es', 'cz', 'hu', 'cy',
        'uk', 'ch', 'gb', 'ru', 'us', 'ca', 'mx', 'bz', 'gu', 'sv', 'hn', 'ni', 'cr', 'pa', 'bs', 'cu', 'jm', 'ht',
        'do', 'kn', 'ag', 'dg', 'bb', 'lc', 'vc', 'gb', 'co', 've', 'gy', 'sr', 'ec', 'pe', 'bo', 'cl', 'ar', 'uy',
        'py', 'br', 'aw', 'bq', 'cw',
        'au', 'nz',
        'il', 'in', 'tw', 'kr', 'jp',
        'za', 'sa', 'ae'].includes(country.toLowerCase());
}
