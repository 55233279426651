import parse from 'html-react-parser';
import React, { CSSProperties, ReactNode } from 'react';

import { TypographyVariantsEnum } from '../../../utils/_enums/typeography-variants.enum';
import styles from './typography.module.scss';

const resolveComponentTypeByVariant = (
    id: TypographyProps['id'],
    variant: TypographyProps['variant'],
    children: TypographyProps['children'],
    element: TypographyProps['element'],
    className: TypographyProps['className'],
    style: TypographyProps['style'],
    disableMarkdown?: TypographyProps['disableMarkdown'],
    inverted?: TypographyProps['inverted']
) => {
    const childrenWithOrWithoutMarkdown =
        disableMarkdown || typeof children !== 'string'
            ? children
            : parse(children as string);

    if (element) {
        return React.createElement(
            element,
            {
                id,
                className: `${styles[variant as string]} ${
                    inverted ? styles['inverted'] : ''
                } ${className}`,
                style,
                'data-testid': 'typography'
            },
            childrenWithOrWithoutMarkdown
        );
    }

    switch (variant) {
        case TypographyVariantsEnum.STAGE: {
            return (
                <h1
                    id={id}
                    data-tetsid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h1>
            );
        }
        case TypographyVariantsEnum.HEADING1: {
            return (
                <h1
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h1>
            );
        }
        case TypographyVariantsEnum.HEADING2_BOLD: {
            return (
                <h2
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h2>
            );
        }
        case TypographyVariantsEnum.HEADING2_LIGHT: {
            return (
                <h2
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h2>
            );
        }
        case TypographyVariantsEnum.VARIANT_HEADING: {
            return (
                <h1
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h1>
            );
        }
        case TypographyVariantsEnum.HEADING3_BOLD: {
            return (
                <h3
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h3>
            );
        }
        case TypographyVariantsEnum.HEADING3_LIGHT: {
            return (
                <h3
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h3>
            );
        }
        case TypographyVariantsEnum.HEADING4_BOLD: {
            return (
                <h4
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h4>
            );
        }
        case TypographyVariantsEnum.HEADING4_LIGHT: {
            return (
                <h4
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h4>
            );
        }
        case TypographyVariantsEnum.HEADING5: {
            return (
                <h5
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h5>
            );
        }
        case TypographyVariantsEnum.HEADING5_LIGHT: {
            return (
                <h5
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h5>
            );
        }
        case TypographyVariantsEnum.HEADING6: {
            return (
                <h6
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h6>
            );
        }
        case TypographyVariantsEnum.INTROTEXT: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.TOPLINE_OR_LABEL: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.CAPTION: {
            return (
                <p
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </p>
            );
        }
        case TypographyVariantsEnum.INPUT: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.BUTTON: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.MOBILENAVLINK: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.TOAST: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.TABLE_TITLE: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.TABLE_VALUE: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.LINK_SMALL:
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        case TypographyVariantsEnum.LINK: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.KEYWORD_TITLE: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.BODY_SMALL: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`${styles[variant]} ${
                        inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.QUOTE: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`
                        ${styles[variant]} 
                        ${inverted ? styles['inverted'] : ''} 
                        ${className ?? ''}
                    `}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.PARAGRAPH: {
            return (
                <p
                    id={id}
                    data-testid="typography"
                    className={`
                        typography
                        ${styles[TypographyVariantsEnum.PARAGRAPH]} 
                        ${inverted ? styles['inverted'] : ''} 
                        ${className ?? ''}
                    `}
                >
                    {childrenWithOrWithoutMarkdown}
                </p>
            );
        }
        case TypographyVariantsEnum.FAQ_HEADING: {
            return (
                <h5
                    id={id}
                    data-testid="typography"
                    className={`
                        ${styles[variant]} 
                        ${inverted ? styles['inverted'] : ''
                    } ${className ?? ''}`}
                    style={style}
                >
                    {childrenWithOrWithoutMarkdown}
                </h5>
            );
        }
        case TypographyVariantsEnum.FOOTER_PARAGRAPH: {
            return (
                <div
                    id={id}
                    data-testid="typography"
                    className={`
                        typography
                        ${styles[TypographyVariantsEnum.FOOTER_PARAGRAPH]} 
                        ${inverted ? styles['inverted'] : ''} 
                        ${className ?? ''}
                    `}
                >
                    {childrenWithOrWithoutMarkdown}
                </div>
            );
        }
        case TypographyVariantsEnum.CONTAINER: {
            return (
                <div
                    id={id}
                    data-testid="typography"
                    className={`
                        typography
                        ${styles[TypographyVariantsEnum.BODY]} 
                        ${inverted ? styles['inverted'] : ''}
                        ${className ?? ''}
                    `}
                >
                    {childrenWithOrWithoutMarkdown}
                </div>
            );
        }
        case TypographyVariantsEnum.FOOTER_LINK: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`
                        typography
                        ${styles[TypographyVariantsEnum.FOOTER_LINK]} 
                        ${inverted ? styles['inverted'] : ''} 
                        ${className ?? ''}
                    `}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        case TypographyVariantsEnum.QUOTE_TEXT: {
            return (
                <p
                    id={id}
                    data-testid="typography"
                    className={`
                        typography
                        ${styles[TypographyVariantsEnum.QUOTE_TEXT]} 
                        ${inverted ? styles['inverted'] : ''} 
                        ${className ?? ''}
                    `}
                >
                    {childrenWithOrWithoutMarkdown}
                </p>
            );
        }
        case TypographyVariantsEnum.COUNTDOWN: {
            return (
                <span
                    id={id}
                    data-testid="typography"
                    className={`
                        typography
                        ${styles[TypographyVariantsEnum.COUNTDOWN]} 
                        ${inverted ? styles['inverted'] : ''} 
                        ${className ?? ''}
                    `}
                >
                    {childrenWithOrWithoutMarkdown}
                </span>
            );
        }
        default: {
            return (
                <p
                    id={id}
                    data-testid="typography"
                    className={`
                        typography
                        ${styles[TypographyVariantsEnum.BODY]} 
                        ${inverted ? styles['inverted'] : ''} 
                        ${className ?? ''}
                    `}
                >
                    {childrenWithOrWithoutMarkdown}
                </p>
            );
        }
    }
};

export interface TypographyProps {
    id?: string;
    variant?: TypographyVariantsEnum;
    children: string | ReactNode | number;
    element?: string;
    className?: string;
    style?: CSSProperties;
    disableMarkdown?: boolean;
    inverted?: boolean;
}

export const Typography: React.FC<TypographyProps> = ({
                                                          id,
                                                          variant,
                                                          children,
                                                          element,
                                                          className,
                                                          style,
                                                          disableMarkdown,
                                                          inverted
                                                      }) => {
    return resolveComponentTypeByVariant(
        id,
        variant,
        children,
        element,
        className,
        style,
        disableMarkdown,
        inverted
    );
};

Typography.defaultProps = {
    variant: TypographyVariantsEnum.BODY,
    disableMarkdown: false
};
