import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CreateSmartContractData, SmartContractShareEntry} from '../../api/models/create-smart-contract-data';
import {SmartContractData} from '../../api/models/smart-contract-data';
import {SmartContractFormData} from '../../api/models/smart-contract-form-data';
import SmartContractService from '../../api/services/smart-contract-service';
import {SmartContractTypes} from '../../utils/_enums/smart-contract-types.enum';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';

interface SmartContractState {
    smartContractFormData?: SmartContractFormData;
    elementType?: SmartContractTypes;
    elementId?: number;
    pending: boolean;
    deploymentSuccess: boolean;
    availableContracts: SmartContractData[];
    usedContract?: SmartContractData;
}

const initialState: SmartContractState = {
    pending: false,
    deploymentSuccess: false,
    availableContracts: []
};

export const createSmartContract = createAsyncThunk(
    'smartContracts/createSmartContract',
    async (data: SmartContractFormData, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const publisherShares: SmartContractShareEntry = {
                uuid: '',
                payeeId: (data.smartContractPubSelect as number),
                shareInit: data.smartContractPubShareInitial,
                shareResaleAfterSellerPayout: data.smartContractPubShareResale
            };
            const backendData: CreateSmartContractData = {
                contractDTO: {
                    contractName: data.smartContractName,
                    contractSymbol: data.smartContractSymbol,
                    contractType: data.contractType,
                    publisherId: (data.smartContractPubSelect as number),
                    blockchain: data.smartContractBlockchain,
                    elementId: data.elementId
                },
                contractShareDTOList: data.beneficiaryShares.length > 0
                    ? [publisherShares, ...data.beneficiaryShares]
                    : [publisherShares]
            }
            const smartContractService = new SmartContractService();
            const smartContractResponse = await smartContractService.createSmartContract(backendData);
            return smartContractResponse.data;
        }, rejectWithValue);
    }
);

export const getAllContractsForVariant = createAsyncThunk(
    'smartContracts/getAllContractsForVariant',
    async (variantId: number, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const smartContractService = new SmartContractService();
            const res = await smartContractService.getAllContractsForVariant(variantId);
            return res.data;
        }, rejectWithValue);
    }
);

export const getVariantContract = createAsyncThunk(
    'smartContracts/getVariantContract',
    async (variantId: number, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const smartContractService = new SmartContractService();
            const res = await smartContractService.getVariantContract(variantId);
            return res.data;
        }, rejectWithValue);
    }
);

export const getAllContractsForProduct = createAsyncThunk(
    'smartContracts/getAllContractsForProduct',
    async (productId: number, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const smartContractService = new SmartContractService();
            const res = await smartContractService.getAllContractsForProduct(productId);
            return res.data;
        }, rejectWithValue);
    }
);

export const getProductContract = createAsyncThunk(
    'smartContracts/getProductContract',
    async (productId: number, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const smartContractService = new SmartContractService();
            const res = await smartContractService.getProductContract(productId);
            return res.data;
        }, rejectWithValue);
    }
);

export const getPublisherContract = createAsyncThunk(
    'smartContracts/getPublisherContract',
    async (publisherId: number, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const smartContractService = new SmartContractService();
            const res = await smartContractService.getPublisherContract(publisherId);
            return res.data;
        }, rejectWithValue);
    }
);

export const smartContractSlice = createSlice({
    name: 'smartContractSlice',
    initialState,
    reducers: {
        setSmartContractDetails: (
            state,
            action: PayloadAction<SmartContractFormData>
        ) => {
            state.smartContractFormData = action.payload
        },
        setElementURLData: (
            state,
            action: PayloadAction<{ elementType: SmartContractTypes; elementId: number }>
        ) => {
            state.elementType = action.payload.elementType;
            state.elementId = action.payload.elementId;
        },
        resetSmartContractState:
            (state) => {
                state.pending = false;
                state.deploymentSuccess = false;
                state.smartContractFormData = undefined;
                state.elementId = undefined;
                state.elementType = undefined;
            },
        resetSmartContracts:
            (state) => {
                state.usedContract = undefined;
                state.availableContracts = [];
            }
    },
    extraReducers: builder => {
        builder
            .addCase(createSmartContract.pending, (state) => {
                state.pending = true;
            })
            .addCase(createSmartContract.fulfilled, (state) => {
                state.pending = false;
                state.deploymentSuccess = true;
            })
            .addCase(createSmartContract.rejected, (state) => {
                state.pending = false;
            })
            .addCase(getAllContractsForVariant.pending, (state) => {
                state.pending = true;
                state.availableContracts = [];
            })
            .addCase(getAllContractsForVariant.fulfilled, (state, action) => {
                state.pending = false;
                state.availableContracts = action.payload;
            })
            .addCase(getAllContractsForVariant.rejected, (state) => {
                state.pending = false;
            })
            .addCase(getAllContractsForProduct.pending, (state) => {
                state.pending = true;
                state.availableContracts = [];
            })
            .addCase(getAllContractsForProduct.fulfilled, (state, action) => {
                state.pending = false;
                state.availableContracts = action.payload;
            })
            .addCase(getAllContractsForProduct.rejected, (state) => {
                state.pending = false;
            })
            .addCase(getVariantContract.pending, (state) => {
                state.pending = true;
                state.usedContract = undefined;
            })
            .addCase(getVariantContract.fulfilled, (state, action) => {
                state.pending = false;
                state.usedContract = action.payload;
            })
            .addCase(getVariantContract.rejected, (state) => {
                state.pending = false;
            })
            .addCase(getProductContract.pending, (state) => {
                state.pending = true;
                state.usedContract = undefined;
            })
            .addCase(getProductContract.fulfilled, (state, action) => {
                state.pending = false;
                state.usedContract = action.payload;
            })
            .addCase(getProductContract.rejected, (state) => {
                state.pending = false;
            })
            .addCase(getPublisherContract.pending, (state) => {
                state.pending = true;
                state.availableContracts = [];
            })
            .addCase(getPublisherContract.fulfilled, (state, action) => {
                state.pending = false;
                state.availableContracts = [action.payload];
            })
            .addCase(getPublisherContract.rejected, (state) => {
                state.pending = false;
            })
    }
});

export const {
    setSmartContractDetails,
    setElementURLData,
    resetSmartContractState,
    resetSmartContracts
} = smartContractSlice.actions;
export default smartContractSlice.reducer;
