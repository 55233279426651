import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../redux';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = <T extends AnyAction>(): Dispatch<T> =>
    useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
