import {backend} from '../../utils/_config/axios';
import {NewsletterRequest} from '../models/newsletter-request';

export class NewsletterService {

    public subscribeToNewsletter(request: NewsletterRequest) {
        return backend(false).post('newsletter', request);
    }

    public userSubscribeToNewsletter(language: string, isNewsletter = true) {
        return backend(true).get(`api/${isNewsletter ? 'newsletter' : 'dropletter'}/subscribe?language=${language}`);
    }

    public userUnsubscribeFromNewsletter(language: string, isNewsletter = true) {
        return backend(true).get(`api/${isNewsletter ? 'newsletter' : 'dropletter'}/unsubscribe?language=${language}`);
    }

    public checkStatusOfSubscription(language: string, isNewsletter = true) {
        return backend(true).get(`api/${isNewsletter ? 'newsletter' : 'dropletter'}?language=${language}`);
    }

}
