import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ContactFormData} from '../../api/models/contact-form-data';
import SupportService from '../../api/services/support.service';
import {ReduxStateModel} from '../../utils/_models/redux-state-model';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';
import {ErrorResponse} from '../../api/models/error-response';

interface SupportState {
    supportModel: ReduxStateModel;
}

const initialState: SupportState = {
    supportModel: {
        pending: false
    }
};

export const sendContactForm = createAsyncThunk(
    'support/sendSupportMail',
    async (data: ContactFormData, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const supportService = new SupportService();
            const productResponse = await supportService.sendSupportMail(data);
            return productResponse.data;
        }, rejectWithValue);
    }
);

const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {
        removeSupportStatus: (state) => {
            state.supportModel.status = undefined;
        },
    },
    extraReducers: {
        [sendContactForm.pending.type]: (state) => {
            state.supportModel.pending = true;
            state.supportModel.status = undefined;
        },
        [sendContactForm.fulfilled.type]: (state) => {
            state.supportModel.pending = false;
            state.supportModel.status = 200;
        },
        [sendContactForm.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.supportModel.status = action.payload.status;
            state.supportModel.pending = false;
        }
    }
});

export const {
    removeSupportStatus
} = supportSlice.actions;
export default supportSlice.reducer;
