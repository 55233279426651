import {
    faCheck,
    faExclamationCircle,
    faInfoCircle,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ToastProps } from 'react-toast-notifications';

import { ToasterTypes } from '../../../utils/_enums/toaster-types.enum';
import { TypographyVariantsEnum } from '../../../utils/_enums/typeography-variants.enum';
import { Typography } from '../typography/typography';
import styles from './toast-notification.module.scss';

export const ToastNotification = (props: ToastProps): JSX.Element => {
    return (
        <div
            className={`${styles['toast']} ${styles[props.appearance]}`}
            data-testid={
                props.appearance === ToasterTypes.SUCCESS
                    ? 'toast-success'
                    : props.appearance === ToasterTypes.INFO
                    ? 'toast-info'
                    : 'toast-error'
            }
        >
            <div className={styles['left-toast']}>
                <div className={styles['info-card']}>
                    <div className={styles['info-card-icon']}>
                        {props.appearance === ToasterTypes.SUCCESS ? (
                            <FontAwesomeIcon icon={faCheck} />
                        ) : props.appearance === ToasterTypes.INFO ? (
                            <FontAwesomeIcon icon={faInfoCircle} />
                        ) : (
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        )}
                    </div>
                    <div className={styles['info-card-arrow']} />
                </div>
                <Typography
                    variant={TypographyVariantsEnum.TOAST}
                    className={styles['toast-text']}
                >
                    {props.children}
                </Typography>
            </div>
            <FontAwesomeIcon
                icon={faTimes}
                onClick={() => props.onDismiss()}
                className={styles['close-icon']}
            />
        </div>
    );
};
