import {
    Action,
    configureStore,
    createImmutableStateInvariantMiddleware,
    ThunkAction
} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import accountMenuSlice from './slices/account-menu-slice';
import accountingReportSlice from './slices/accounting-report-slice';
import authentificationReducer from './slices/authentification-slice';
import blockchainSlice from './slices/blockchain-slice';
import checkoutSlice from './slices/checkout-slice';
import creatokenSlice from './slices/creatoken-slice';
import globalSlice from './slices/global-slice';
import invoiceAddressSlice from './slices/invoice-address-slice';
import mintSlice from './slices/mint-slice';
import myOanSlice from './slices/my-oan.slice';
import newsletterSlice from './slices/newsletter-slice';
import productSlice from './slices/product-slice';
import resaleSlice from './slices/resale-slice';
import socialNetworksSlice from './slices/social-networks-slice';
import supportSlice from './slices/support.slice';
import userSlice from './slices/user-slice';
import walletSlice from './slices/wallet-slice';
import smartContractSlice from './slices/smart-contract-slice';

const middleware =
    process.env.NODE_ENV !== 'production'
        ? [createImmutableStateInvariantMiddleware(), thunk]
        : [thunk];

export const store = configureStore({
    reducer: {
        authentification: authentificationReducer,
        accountMenu: accountMenuSlice,
        blockchainSlice: blockchainSlice,
        supportSlice: supportSlice,
        invoiceAddress: invoiceAddressSlice,
        productSlice: productSlice,
        checkoutSlice: checkoutSlice,
        newsletterSlice: newsletterSlice,
        userSlice: userSlice,
        socialNetworksSlice: socialNetworksSlice,
        mintSlice: mintSlice,
        resaleSlice: resaleSlice,
        myOanSlice: myOanSlice,
        creatokenSlice: creatokenSlice,
        walletSlice: walletSlice,
        globalSlice: globalSlice,
        accountingReportSlice: accountingReportSlice,
        smartContractSlice: smartContractSlice
    },
    middleware
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
