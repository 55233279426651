import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ErrorResponse} from '../../api/models/error-response';
import {InstanceStatus} from '../../api/models/instance-status';
import InstancesService from '../../api/services/instances.service';
import SignatureService from '../../api/services/signature.service';
import TokenContractService from '../../api/services/token-contract.service';
import {ReduxStateModel} from '../../utils/_models/redux-state-model';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';
import {setStatusOfDetailedInstance} from './product-slice';

interface MintState {
    mintInstanceModel: ReduxStateModel;
}

const initialState: MintState = {
    mintInstanceModel: {
        pending: false
    }
};

export const mintInstance = createAsyncThunk(
    'mint/mintInstance',
    async ({tokenContractAddress, instanceId, walletAddress}:
               { tokenContractAddress: string; instanceId: number; walletAddress: string },
           {rejectWithValue, dispatch}) => {
        return await reduxThunkWrapper(async () => {
            const signatureService = new SignatureService();
            const signatureRes = await signatureService.getMintSignature(instanceId, walletAddress);

            const tokenContractService = new TokenContractService(tokenContractAddress);
            await tokenContractService.mint(instanceId, signatureRes.data.signature);

            dispatch(setStatusOfDetailedInstance(InstanceStatus.MINTING));

            const instancesService = new InstancesService();
            await instancesService.setTokenOnMint(instanceId);
        }, rejectWithValue);
    }
);

export const mintSlice = createSlice({
    name: 'mint',
    initialState,
    reducers: {},
    extraReducers: {
        [mintInstance.pending.type]: (state) => {
            state.mintInstanceModel.pending = true;
            state.mintInstanceModel.status = undefined;
        },
        [mintInstance.fulfilled.type]: (state) => {
            state.mintInstanceModel.pending = false;
            state.mintInstanceModel.status = 200;
        },
        [mintInstance.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.mintInstanceModel.status = action.payload?.status;
            state.mintInstanceModel.pending = false;
        }
    }
});

export default mintSlice.reducer;
