import {Contract} from 'ethers';

import initialDropContractABI from '../../utils/_config/initial-drop-contract-abi';
import {web3Instance} from '../../utils/_helper/window-instances';

export default class InitialDropContractService {

    private contract: Contract;

    constructor() {
        const signer = web3Instance.getSigner();
        this.contract = new Contract((process.env.NEXT_PUBLIC_INITIAL_DROP_CONTRACT as string), initialDropContractABI, signer);
    }

    public buyAuction(tokenContractAddress: string,
                      tokenId: number,
                      nonce: number,
                      maxBlock: number,
                      signature: string): Promise<any> {
        return this.contract
            .buyAuction(tokenContractAddress, tokenId, nonce, maxBlock, signature);
    }

    public buyFixPrice(tokenContractAddress: string,
                       tokenId: number,
                       nonce: string,
                       maxBlock: number,
                       priceInWei: string,
                       signature: string): Promise<any> {
        return this.contract
            .buyFixPrice(tokenContractAddress, tokenId, nonce, maxBlock, signature, {value: priceInWei});
    }
}
