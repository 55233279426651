import {ethers} from 'ethers';

import {web3Instance} from './window-instances';

const signHash = async (hash: string): Promise<string> => {
    const signer = web3Instance.getSigner();
    const messageHashBinary = ethers.utils.arrayify(hash);
    return await signer.signMessage(messageHashBinary);
}

const signMessage = async (message: string): Promise<string> => {
    const signer = web3Instance.getSigner();
    return await signer.signMessage(message);
}

export {signHash, signMessage};