import axios, { AxiosInstance } from 'axios';
import getConfig from 'next/config';

import { store } from '../../redux';

const {publicRuntimeConfig} = getConfig();

export const backend = (withToken = false): AxiosInstance => {

    return axios.create({
        withCredentials: true,
        baseURL: publicRuntimeConfig.backendURL,
        headers: withToken
            ? {
                common: { Authorization: `Bearer ${store.getState().authentification.jwt}` }
            }
            : undefined
    });
};

export const typo = axios.create({
    baseURL: publicRuntimeConfig.typoURL,
    params: { secret: 'sDehs6REWa8' },
    headers: {
        Authorization: `Bearer ${store.getState().authentification.jwt}`
    }
});