import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import WalletService from '../../api/services/wallet.service';
import {ResponseCodes} from '../../utils/_enums/response-codes.enum';
import {signMessage} from '../../utils/_helper/sign-helper';
import {ReduxStateModel} from '../../utils/_models/redux-state-model';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';
import {setMintedInstances, updateAttachments} from './product-slice';
import {setWallet} from './user-slice';

interface WalletState {
    addWalletModel: ReduxStateModel;
    getUnlockablesWithWalletModel: ReduxStateModel;
}

const initialState: WalletState = {
    addWalletModel: {
        pending: false
    },
    getUnlockablesWithWalletModel: {
        pending: false
    }
};

export const addWallet = createAsyncThunk(
    'wallet/addWallet',
    async (wallet: string, {rejectWithValue, dispatch}) => {
        return await reduxThunkWrapper(async () => {
            const walletService = new WalletService();
            const res = await walletService.initializeWalletConnection();
            let signRes = '';
            try {
                signRes = await signMessage(res.data.uuid);
            } catch {
                return rejectWithValue({data: {code: ResponseCodes.ABORT_METAMASK_SIGNING}});
            }
            await walletService.addWallet({
                wallet: wallet,
                token: res.data.token,
                signature: signRes
            });
            dispatch(setWallet(wallet));
        }, rejectWithValue);
    }
);

export const removeWallet = createAsyncThunk(
    'wallet/removeWallet',
    async (_, {rejectWithValue, dispatch}) => {
        return await reduxThunkWrapper(async () => {
            const walletService = new WalletService();
            await walletService.removeWallet();
            dispatch(setWallet(''));
            dispatch(setMintedInstances([]));
        }, rejectWithValue);
    }
);

export const getUnlockablesWithWallet = createAsyncThunk(
    'wallet/getUnlockablesWithWallet',
    async (data: { wallet: string; variant: number }, {rejectWithValue, dispatch}) => {
        return await reduxThunkWrapper(async () => {
            const walletService = new WalletService();
            const res = await walletService.initializeGetUnlockablesWithWallet();
            let signRes = '';
            try {
                signRes = await signMessage(res.data.uuid);
            } catch {
                return rejectWithValue({data: {code: ResponseCodes.ABORT_METAMASK_SIGNING}});
            }
            const unlockables = await walletService.getUnlockablesWithWallet({
                wallet: data.wallet,
                token: res.data.token,
                signature: signRes,
                variant: data.variant
            });
            dispatch(updateAttachments(unlockables.data));
        }, rejectWithValue);
    }
);

export const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        resetWalletModel: (state) => {
            state.addWalletModel = {
                pending: false
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addWallet.pending, (state) => {
                state.addWalletModel.pending = true;
                state.addWalletModel.status = undefined;
            })
            .addCase(addWallet.fulfilled, (state) => {
                state.addWalletModel.pending = false;
                state.addWalletModel.status = ResponseCodes.OK;
            })
            .addCase(addWallet.rejected, (state, action: PayloadAction<any>) => {
                state.addWalletModel.pending = false;
                state.addWalletModel.status = action.payload?.data?.code;
            })
            .addCase(getUnlockablesWithWallet.pending, (state) => {
                state.getUnlockablesWithWalletModel.pending = true;
                state.getUnlockablesWithWalletModel.status = undefined;
            })
            .addCase(getUnlockablesWithWallet.fulfilled, (state) => {
                state.getUnlockablesWithWalletModel.pending = false;
                state.getUnlockablesWithWalletModel.status = ResponseCodes.OK;
            })
            .addCase(getUnlockablesWithWallet.rejected, (state, action: PayloadAction<any>) => {
                state.getUnlockablesWithWalletModel.pending = false;
                state.getUnlockablesWithWalletModel.status = action.payload?.data?.code;
            })
    }
});

export const {
    resetWalletModel
} = walletSlice.actions;
export default walletSlice.reducer;
