import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {InstanceStatus} from '../../api/models/instance-status';
import {ResaleFixPrice} from '../../api/models/resale-fix-price-data';
import InstancesService from '../../api/services/instances.service';
import {ReduxStateModel} from '../../utils/_models/redux-state-model';
import {setStatusOfDetailedInstance, updateDetailedInstance} from './product-slice';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';
import {ErrorResponse} from '../../api/models/error-response';

interface ResaleState {
    sellInstanceFixPriceModel: ReduxStateModel;
    cancelFixPriceModel: ReduxStateModel;
    status?: number;
}

const initialState: ResaleState = {
    sellInstanceFixPriceModel: {
        pending: false
    },
    cancelFixPriceModel: {
        pending: false
    }
};

export const sellInstanceFixPrice = createAsyncThunk(
    'resale/sellInstanceFixPrice',
    async ({instanceId, data}: { instanceId: number; data: ResaleFixPrice }, {rejectWithValue, dispatch}) => {
        return await reduxThunkWrapper(async () => {
            const instancesService = new InstancesService();
            const newInstance = await instancesService.sellInstanceFixPrice(instanceId, data);
            dispatch(updateDetailedInstance(newInstance.data));
        }, rejectWithValue);
    }
);

export const cancelFixPrice = createAsyncThunk(
    'resale/cancelFixPrice',
    async (tokenId: number, {rejectWithValue, dispatch}) => {
        return await reduxThunkWrapper(async () => {
            const instancesService = new InstancesService();
            await instancesService.stopResale(tokenId);
            dispatch(setStatusOfDetailedInstance(InstanceStatus.SOLD));
        }, rejectWithValue);
    }
);

export const resaleSlice = createSlice({
    name: 'resale',
    initialState,
    reducers: {
        removeCancelFixPriceStatus: (state) => {
            state.cancelFixPriceModel.status = undefined;
        },
        removeSellInstanceFixPriceStatus: (state) => {
            state.sellInstanceFixPriceModel.status = undefined;
        }
    },
    extraReducers: {
        [sellInstanceFixPrice.pending.type]: (state) => {
            state.sellInstanceFixPriceModel.pending = true;
            state.sellInstanceFixPriceModel.status = undefined;
        },
        [sellInstanceFixPrice.fulfilled.type]: (state) => {
            state.sellInstanceFixPriceModel.status = 200;
            state.sellInstanceFixPriceModel.pending = false;
        },
        [sellInstanceFixPrice.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.sellInstanceFixPriceModel.status = action.payload.status;
            state.sellInstanceFixPriceModel.pending = false;
        },
        [cancelFixPrice.pending.type]: (state) => {
            state.cancelFixPriceModel.status = undefined;
            state.cancelFixPriceModel.pending = true;
        },
        [cancelFixPrice.fulfilled.type]: (state) => {
            state.cancelFixPriceModel.status = 200;
            state.cancelFixPriceModel.pending = false;
        },
        [cancelFixPrice.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.cancelFixPriceModel.status = action.payload.status;
            state.cancelFixPriceModel.pending = false;
        }
    }
});

export const {
    removeCancelFixPriceStatus,
    removeSellInstanceFixPriceStatus
} = resaleSlice.actions;
export default resaleSlice.reducer;
