import {AxiosPromise} from 'axios';

import {backend} from '../../utils/_config/axios';
import {SocialNetworks} from '../models/social-networks';

export default class SocialNetworksService {
    public fetchSocialNetworks(): AxiosPromise<SocialNetworks> {
        return backend(true).get('/api/socialnetwork');
    }

    public updateSocialNetworks(data: SocialNetworks): AxiosPromise {
        return backend(true).post('/api/socialnetwork', data);
    }
}
