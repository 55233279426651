import { Action, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DetailedInstance } from '../../api/models/detailed-instance';
import { ErrorResponse } from '../../api/models/error-response';
import { Instance } from '../../api/models/instance';
import { InstanceStatus } from '../../api/models/instance-status';
import { Product } from '../../api/models/product';
import { User } from '../../api/models/user';
import { UserRole } from '../../api/models/user-role';
import { Variant } from '../../api/models/variant';
import { VariantImage } from '../../api/models/variant-image';
import { VariantImageType } from '../../api/models/variant-image-type';
import InstancesService from '../../api/services/instances.service';
import ProductService from '../../api/services/product.service';
import UsersService from '../../api/services/users.service';
import VariantService from '../../api/services/variant.service';
import { FilterExpression } from '../../utils/_enums/filter-expression.enum';
import { StrapiTeaserElementTypes } from '../../utils/_enums/strapi-teaser-element-types.enum';
import { dateToISOString } from '../../utils/_helper/time-helper';
import { FilterItem } from '../../utils/_models/filter-item';
import { ImageModel } from '../../utils/_models/image-model';
import { InstanceForm } from '../../utils/_models/instance-form';
import { Pagination } from '../../utils/_models/pagination';
import { PaginationModel } from '../../utils/_models/pagination-model';
import { ProductAttribute } from '../../utils/_models/product-attribute';
import { ProductStateModel } from '../../utils/_models/product-state-model';
import { ProductWithVariants } from '../../utils/_models/product-with-variants';
import { TeaserElementModel } from '../../utils/_models/teaser-element-model';
import { reduxThunkWrapper } from '../_helper/redux-thunk-wrapper';
import { SortingOptionsEnum } from "../../utils/_enums/sorting-options.enum";

interface ProductState {
    pending: boolean;
    additionalAttributes: ProductAttribute[];
    additionalFiles: ProductAttribute[];
    variantAttributes: ProductAttribute[];
    error?: string;
    products: Product[];
    productPagination: Pagination;
    productPaginationReleased: Pagination;
    adminProductsMaxItems: number;
    adminProducts: ProductWithVariants[];
    adminProductsReleasedMaxItems: number;
    adminProductsReleased: ProductWithVariants[];
    variants: Variant[];
    variant?: Variant;
    instance?: DetailedInstance;
    product?: ProductStateModel;
    publishers: User[];
    originators: User[];
    fileIds: number[];
    fulfilled: boolean;
    uniqueInstances: PaginationModel<DetailedInstance[]>;
    detailedInstances: PaginationModel<DetailedInstance[]>;
    moreInstances: PaginationModel<DetailedInstance[]>;
    myOanInstances: PaginationModel<DetailedInstance[]>;
    comingSoonVariants: PaginationModel<Variant[]>;
    allVariants: PaginationModel<Variant[]>;
    moreVariants: PaginationModel<Variant[]>;
    variantInstancesElements: TeaserElementModel<Variant[]>[];
    featuredVariants: PaginationModel<Variant[]>;
    staticPageVariants: PaginationModel<Variant[]>;
    allVariantsSorting: SortingOptionsEnum;
    ownedInstances: {
        pending: boolean;
        status?: number;
        mintedInstances: DetailedInstance[];
        boughtInstances: DetailedInstance[];
        offsetBought: number;
        offsetMinted: number;
    };
    attachments?: VariantImage[];
}

const initialPaginationModel: PaginationModel<Variant[] | Instance[] | DetailedInstance[]> = {
    data: [],
    pagination: {
        page: 0,
        size: 24
    },
    pending: false,
    fulfilled: false,
    maxNumber: 0,
    filter: []
};

const initialState: ProductState = {
    pending: false,
    fulfilled: false,
    error: '',
    additionalAttributes: [],
    adminProductsReleased: [],
    publishers: [],
    originators: [],
    variantAttributes: [],
    additionalFiles: [],
    variants: [],
    products: [],
    adminProducts: [],
    ownedInstances: {
        boughtInstances: [],
        mintedInstances: [],
        pending: false,
        offsetBought: 0,
        offsetMinted: 0
    },
    allVariantsSorting: SortingOptionsEnum.DATE_DESC,
    featuredVariants: initialPaginationModel as PaginationModel<Variant[]>,
    uniqueInstances: initialPaginationModel as PaginationModel<DetailedInstance[]>,
    detailedInstances: initialPaginationModel as PaginationModel<DetailedInstance[]>,
    moreInstances: initialPaginationModel as PaginationModel<DetailedInstance[]>,
    myOanInstances: initialPaginationModel as PaginationModel<DetailedInstance[]>,
    comingSoonVariants: initialPaginationModel as PaginationModel<Variant[]>,
    allVariants: initialPaginationModel as PaginationModel<Variant[]>,
    moreVariants: initialPaginationModel as PaginationModel<Variant[]>,
    staticPageVariants: initialPaginationModel as PaginationModel<Variant[]>,
    variantInstancesElements: [],
    productPagination: {
        page: 0,
        size: 20
    },
    productPaginationReleased: {
        page: 0,
        size: 20
    },
    adminProductsMaxItems: 0,
    adminProductsReleasedMaxItems: 0,
    fileIds: []
};


const getInstancesWithFilter = async (filterItems: FilterItem[]) => {
    const instanceService = new InstancesService();
    const instanceResponse = await instanceService.getDetailedInstancesWithFilter(
        filterItems
    );

    return {
        data: instanceResponse.data,
        pagination: {
            page: parseInt(filterItems[1].value),
            size: parseInt(filterItems[0].value)
        },
        maxNumber: Number(instanceResponse.headers.last)
    };
}

const getVariantsWithFilter = async (filterItems: FilterItem[]) => {
    const variantService = new VariantService();
    const variantsResponse = await variantService.getDetailedVariants(
        filterItems
    );

    return {
        data: variantsResponse.data,
        pagination: {
            page: parseInt(filterItems[1].value),
            size: parseInt(filterItems[0].value)
        },
        maxNumber: Number(variantsResponse.headers.last)
    };
}

const getElementsByIds = async (filter: FilterItem[], type: StrapiTeaserElementTypes, teaserIdentifier: number) => {
    if (type === StrapiTeaserElementTypes.VARIANTS) {
        const variantService = new VariantService();
        const variantsResponse = await variantService.getElementsByIds(filter);
        const responseTypeWrapper = {
            data: variantsResponse.data,
            elementType: StrapiTeaserElementTypes.VARIANTS,
            teaserIdentifier,
            filter,
            maxElements: variantsResponse.headers.last
        }
        return responseTypeWrapper
    } else {
        const instancesService = new InstancesService();
        const instancesResponse = await instancesService.getElementsByIds(filter);
        const responseTypeWrapper = {
            data: instancesResponse.data,
            elementType: StrapiTeaserElementTypes.INSTANCES,
            teaserIdentifier,
            filter,
            maxElements: instancesResponse.headers.last
        }
        return responseTypeWrapper;
    }


};

export const createProduct = createAsyncThunk(
    'products/createProduct',
    async (data: Product, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const productService = new ProductService();
            const productResponse = await productService.createProduct(data);
            return productResponse.data;
        }, rejectWithValue);
    }
);

export const editProduct = createAsyncThunk(
    'products/editProduct',
    async (data: Product, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const productService = new ProductService();
            const productResponse = await productService.editProductById(data);
            return productResponse.data;
        }, rejectWithValue);
    }
);

export const editInstance = createAsyncThunk(
    'products/editInstance',
    async (data: { form: InstanceForm, instanceId: number }, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const instanceService = new InstancesService();
            const instanceResponse = await instanceService.editInstancesById({
                ...data.form,
                saleFrom: dateToISOString(data.form.saleFrom),
                saleTo: data.form.saleTo
                    ? dateToISOString(data.form.saleTo)
                    : undefined
            }, data.instanceId);
            return instanceResponse.data;
        }, rejectWithValue);
    }
);

export const editVariant = createAsyncThunk(
    'products/editVariant',
    async (data: Variant, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            if (data.id) {
                const variantService = new VariantService();
                const variantResponse = await variantService.editVariant(
                    { ...data },
                    data.id
                );
                return variantResponse.data;
            }
        }, rejectWithValue);
    }
);

export const editVariantForProduct = createAsyncThunk(
    'products/editVariantForProduct',
    async (data: Variant, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            if (data.id) {
                const variantService = new VariantService();
                const variantResponse = await variantService.editVariant(
                    { ...data },
                    data.id
                );
                return variantResponse.data;
            }
        }, rejectWithValue);
    }
);

export const addVariant = createAsyncThunk(
    'products/addVariant',
    async (data: Variant, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const variantService = new VariantService();
            const variantResponse = await variantService.addVariant(data);
            return variantResponse.data;
        }, rejectWithValue);
    }
);

export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async (_, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const productService = new ProductService();
            const productResponse = await productService.getAllProducts([]);
            return productResponse.data;
        }, rejectWithValue);
    }
);

export const getPublisherById = createAsyncThunk(
    'products/fetchPublishers',
    async (_, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const productService = new ProductService();
            const productResponse = await productService.getAllProducts([]);
            return productResponse.data;
        }, rejectWithValue);
    }
);

export const fetchUniqueInstances = createAsyncThunk(
    'products/fetchUniqueInstances',
    async (filterItems: FilterItem[], { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            return await getInstancesWithFilter(filterItems);
        }, rejectWithValue);
    }
);

export const fetchDetailedInstances = createAsyncThunk(
    'products/fetchDetailedInstances',
    async (filterItems: FilterItem[], { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            return await getInstancesWithFilter(filterItems);
        }, rejectWithValue);
    }
);

export const fetchMoreInstances = createAsyncThunk(
    'products/fetchMoreInstances',
    async (filterItems: FilterItem[], { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            return await getInstancesWithFilter(filterItems);
        }, rejectWithValue);
    }
);

export const fetchMyOanInstances = createAsyncThunk(
    'products/fetchMyOanInstances',
    async (userId: number, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const instancesService = new InstancesService();
            const instancesRes = await instancesService.getMyOanInstances(userId);
            return instancesRes.data;
        }, rejectWithValue);
    }
);

export const fetchOwnedInstances = createAsyncThunk(
    'products/fetchOwnedInstances',
    async (walletAddress: string, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const instanceService = new InstancesService();
            const instanceResponse = await instanceService.getOwnedInstances(walletAddress);
            return { minted: instanceResponse.data.minted, not_minted: instanceResponse.data.not_minted };
        }, rejectWithValue);
    }
);

export const fetchVariants = createAsyncThunk(
    'products/fetchVariants',
    async (filterItems: FilterItem[], { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            return await getVariantsWithFilter(filterItems);
        }, rejectWithValue);

    }
);

export const fetchMoreVariants = createAsyncThunk(
    'products/fetchMoreVariants',
    async (filterItems: FilterItem[], { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            return await getVariantsWithFilter(filterItems);
        }, rejectWithValue);

    }
);

export const fetchStaticPageVariants = createAsyncThunk(
    'products/fetchStaticPageVariants',
    async (filterItems: FilterItem[], { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            return await getVariantsWithFilter(filterItems);
        }, rejectWithValue);

    }
);

export const fetchElements = createAsyncThunk(
    'products/fetchElementsByIds',
    async (data: { filter: FilterItem[], elementType: StrapiTeaserElementTypes, teaserIdentifier: number }, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            return await getElementsByIds(data.filter, data.elementType, data.teaserIdentifier);
        }, rejectWithValue);

    }
);

export const fetchFeaturedVariants = createAsyncThunk(
    'products/fetchFeaturedVariants',
    async (_, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const filter: FilterItem[] = [
                {
                    param: 'featured',
                    comparisonType: FilterExpression.equals,
                    value: 'true'
                },
                {
                    param: 'random',
                    comparisonType: FilterExpression.none,
                    value: '5'
                }
            ];
            const variantService = new VariantService();
            const variantsResponse = await variantService.getRandomVariants(
                filter
            );
            return variantsResponse.data;
        }, rejectWithValue);
    }
);

export const fetchComingSoonVariants = createAsyncThunk(
    'products/fetchComingSoonVariants',
    async (filterItems: FilterItem[], { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            return await getVariantsWithFilter(filterItems);
        }, rejectWithValue);
    }
);

export const fetchProduct = createAsyncThunk(
    'products/fetchProduct',
    async (productId: number, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const productService = new ProductService();
            const productResponse = await productService.getProductById(
                productId
            );
            return productResponse.data;
        }, rejectWithValue);
    }
);

export const fetchInstance = createAsyncThunk(
    'products/fetchInstance',
    async (instanceId: number, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const instancesService = new InstancesService();
            const instanceResponse = await instancesService.getInstancesById(
                instanceId
            );
            return instanceResponse.data;
        }, rejectWithValue);
    }
);

export const fetchProductByName = createAsyncThunk(
    'products/fetchProductByName',
    async (name: string, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const productService = new ProductService();
            const productResponse = await productService.getProductByName(name);
            return productResponse.data;
        }, rejectWithValue);
    }
);

export const fetchOriginatorForProduct = createAsyncThunk(
    'products/fetchOriginatorForProduct',
    async (originatorsIds: number[], { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const userService = new UsersService();
            const result: User[] = [];
            for (const originatorid of originatorsIds) {
                result.push(await (await userService.getPublicUserById(originatorid))
                    .data[0]);
            }
            return result;
        }, rejectWithValue);
    }
);

export const fetchPublisherForProduct = createAsyncThunk(
    'products/fetchPublisherForProduct',
    async (publisherId: number, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const userService = new UsersService();
            return (await userService.getPublicUserById(publisherId))
                .data;
        }, rejectWithValue);
    }
);

export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async (productId: number, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const productService = new ProductService();
            await productService.deleteProductById(productId);
            return productId;
        }, rejectWithValue);
    }
);

export const addFile = createAsyncThunk(
    'products/addFile',
    async (file: VariantImage, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const variantService = new VariantService();
            const imageResponse = await variantService.addFile(file);
            return imageResponse.data;
        }, rejectWithValue);
    }
);

export const removePermFile = createAsyncThunk(
    'products/removePermFile',
    async (id: number, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const variantService = new VariantService();
            const imageResponse = await variantService.deletePermAttachments(id);
            return imageResponse.data;
        }, rejectWithValue);
    }
);

export const publishInstancesOfVariant = createAsyncThunk(
    'products/publishInstances',
    async (instanceData: InstanceForm, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const instanceService = new InstancesService();
            const instanceResponse = await instanceService.publishInstances({
                ...instanceData,
                variantId: instanceData.variantId,
                mintingCount: typeof instanceData.mintingCount === 'number'
                    ? instanceData.mintingCount
                    : parseInt(instanceData.mintingCount),
                price: parseFloat((instanceData.price as unknown as string).split(',').join('.')),
                saleFrom: dateToISOString(instanceData.saleFrom),
                saleTo: instanceData.saleTo
                    ? dateToISOString(instanceData.saleTo)
                    : undefined
            });
            return instanceResponse.data;
        }, rejectWithValue);
    }
);

export const fetchVariantsOfProduct = createAsyncThunk(
    'products/fetchVariantsOfProduct',
    async (productId: number, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const variantService = new VariantService();
            const variantResponse = await variantService.getVariantsByProductId(
                productId
            );
            return variantResponse.data;
        }, rejectWithValue);
    }
);

const mapVariantImageToProductArtifact = (
    variantImage: VariantImage
): ImageModel => {
    return {
        ...variantImage,
        contentContentType: variantImage.contentContentType,
        isOld: true,
        name: variantImage.name,
        id: variantImage.id
    };
};

export const fetchAllOriginators = createAsyncThunk(
    'products/fetchOriginators',
    async (_, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const userService = new UsersService();
            const userResponse = await userService.searchOriginatorsByName(
                ''
            );
            return userResponse.data;
        }, rejectWithValue);
    }
);

export const fetchAllPublishers = createAsyncThunk(
    'products/fetchPublishers',
    async (_, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const userService = new UsersService();
            const userResponse = await userService.getUsersByRole(
                UserRole.PUBLISHER
            );
            return userResponse.data;
        }, rejectWithValue);
    }
);

export const getVariant = createAsyncThunk(
    'products/fetchVariant',
    async (variantId: number, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const variantService = new VariantService();
            const variantResponse = await variantService.getVariantById(
                variantId
            );
            const imageResponse = await variantService.getPermAttachments(
                variantId
            );
            const productArtifact = imageResponse.data.find(
                (file) => file.type === VariantImageType.PRODUCT_ARTIFACT
            );
            const coverArtIds = imageResponse.data.filter(
                (file) => file.type === VariantImageType.COVER_ARTWORK
            );
            const sellingConditionFiles = imageResponse.data.filter(
                (file) => file.type === VariantImageType.SALES_CONDITIONS
            );
            const productSamples = imageResponse.data.filter(
                (file) => file.type === VariantImageType.PRODUCT_SAMPLE
            );
            const additionalFiles: ProductAttribute[] = imageResponse.data
                .filter(
                    (file) => file.type === VariantImageType.ADDITIONAL_FILES
                )
                .map((file) => {
                    return {
                        id: file.id,
                        description: file.description,
                        name: file.name,
                        content: file.content,
                        file: mapVariantImageToProductArtifact(file)
                    };
                });

            return {
                ...variantResponse.data,
                productArtifactId: productArtifact
                    ? mapVariantImageToProductArtifact(productArtifact)
                    : null,
                coverArtIds: coverArtIds
                    ? coverArtIds.map((file) =>
                        mapVariantImageToProductArtifact(file))
                    : [],
                sellingConditionFileIds: sellingConditionFiles
                    ? sellingConditionFiles.map((file) =>
                        mapVariantImageToProductArtifact(file))
                    : [],
                productSampleIds: productSamples
                    ? productSamples.map((file) =>
                        mapVariantImageToProductArtifact(file))
                    : [],
                additionalFiles
            };
        }, rejectWithValue);
    }
);

export const deleteVariant = createAsyncThunk(
    'products/deleteVariant',
    async (variantId: number, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const variantService = new VariantService();
            await variantService.deleteVariantById(variantId);
            return variantId;
        }, rejectWithValue);
    }
);

export const deleteInstance = createAsyncThunk(
    'products/deleteInstance',
    async (data: { instanceId: number, variantId: number, productId: number }, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const instanceService = new InstancesService();
            await instanceService.deleteInstancesById(data.instanceId);
            return data;
        }, rejectWithValue);
    }
);

export const deleteFile = createAsyncThunk(
    'products/deleteFile',
    async (fileId: number, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const variantService = new VariantService();
            await variantService.deletePermAttachments(fileId);
            return fileId;
        }, rejectWithValue);
    }
);

export const fetchAttachments = createAsyncThunk(
    'products/fetchAttachments',
    async (variantId: number, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const variantService = new VariantService();
            const res = await variantService.getPermAttachments(variantId);
            return res.data;
        }, rejectWithValue);
    }
);

export const getReleasedProductWithVariant = createAsyncThunk(
    'products/fetchReleasedVariantsOfProducts',
    async (pagination: Pagination, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const productService = new ProductService();
            const productResp = await productService.getAllProducts([
                {
                    value: pagination.size.toString(),
                    param: 'size',
                    comparisonType: FilterExpression.none
                },
                {
                    value: pagination.page.toString(),
                    param: 'page',
                    comparisonType: FilterExpression.none
                },
                {
                    value: 'true',
                    param: 'publishedAt',
                    comparisonType: FilterExpression.specified
                }
            ]);
            return {
                data: productResp.data.map((prod) => {
                    return {
                        product: prod,
                        variants: prod.variants
                    };
                }),
                pagination,
                maxItems: productResp.headers.last
            };
        }, rejectWithValue);
    }
);

export const getProductWithVariant = createAsyncThunk(
    'products/fetchVariantsOfProducts',
    async (pagination: Pagination, { rejectWithValue }) => {
        return await reduxThunkWrapper(async () => {
            const productService = new ProductService();
            const productResp = await productService.getAllProducts([
                {
                    value: pagination.size.toString(),
                    param: 'size',
                    comparisonType: FilterExpression.none
                },
                {
                    value: pagination.page.toString(),
                    param: 'page',
                    comparisonType: FilterExpression.none
                },
                {
                    value: 'false',
                    param: 'publishedAt',
                    comparisonType: FilterExpression.specified
                }
            ]);
            return {
                data: productResp.data.map((prod) => {
                    return {
                        product: prod,
                        variants: prod.variants
                    };
                }),
                pagination,
                maxItems: productResp.headers.last
            };
        }, rejectWithValue);
    }
);

const isProductSlicePending = (action: Action) => {
    return action.type.startsWith('productSlice') && action.type.endsWith('pending');
}

const isProductSliceFinished = (action: Action) => {
    return action.type.startsWith('productSlice') &&
        (action.type.endsWith('rejected') || action.type.endsWith('fulfilled'));
}

const isProductSliceFulfilled = (action: Action) => {
    return action.type.startsWith('productSlice') && action.type.endsWith('fulfilled');
}


const isProductSliceRejected = (action: Action) => {
    return action.type.startsWith('productSlice') && action.type.endsWith('rejected');
}


export const productSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
        addAdditionalAttribute: (
            state,
            action: PayloadAction<ProductAttribute>
        ) => {
            state.additionalAttributes.push(action.payload);
        },
        updateAllVariantsSorting: (
            state,
            action: PayloadAction<SortingOptionsEnum>
        ) => {
            state.allVariantsSorting = action.payload;
        },
        updateAdditionalAttribute: (
            state,
            action: PayloadAction<ProductAttribute>
        ) => {
            const index = state.additionalAttributes.findIndex(
                (att) => att.name === action.payload.name
            );
            state.additionalAttributes[index] = action.payload;
        },
        removeAdditionalAttribute: (
            state,
            action: PayloadAction<ProductAttribute>
        ) => {
            const indexToRemove = state.additionalAttributes.findIndex(
                (attribute) => attribute.name === action.payload.name
            );
            state.additionalAttributes.splice(indexToRemove, 1);
        },
        addAdditionalFile: (state, action: PayloadAction<ProductAttribute>) => {
            state.additionalFiles.push(action.payload);
        },
        removeAdditionalFile: (
            state,
            action: PayloadAction<ProductAttribute>
        ) => {
            const indexToRemove = state.additionalFiles.findIndex(
                (attribute) => attribute.name === action.payload.name
            );
            state.additionalFiles.splice(indexToRemove, 1);
        },
        removeFile: (state, action: PayloadAction<number>) => {
            const indexToRemove = state.fileIds.findIndex(
                (fileId) => fileId === action.payload
            );
            state.fileIds.splice(indexToRemove, 1);
        },
        setDetailProduct: (state, action: PayloadAction<number>) => {
            const product = state.adminProducts.find(
                (prod) => prod.product.id === action.payload
            )?.product;
            if (product) {
                state.additionalAttributes = product.productAttributes ?? [];
                state.product = product;
            }
        },
        resetDetailProduct: (state) => {
            state.product = undefined;
            state.fulfilled = false;
            state.error = undefined;
            state.fileIds = [];
        },
        resetDetailVariant: (state) => {
            state.variant = undefined;
            state.additionalFiles = [];
            state.variantAttributes = state.variantAttributes.map(att => {
                return {
                    ...att,
                    value: ''
                }
            });
            state.additionalAttributes = state.additionalAttributes.map(att => {
                return {
                    ...att,
                    value: ''
                }
            });
        },
        setFilter: (state, action: PayloadAction<FilterItem[]>) => {
            state.detailedInstances.filter = action.payload;
        },
        setVariant: (state, action: PayloadAction<Variant>) => {
            state.variant = action.payload;
        },
        updateDetailedInstance: (state, action: PayloadAction<Instance | DetailedInstance>) => {
            if (state.instance) {
                if ('variant' in action.payload && action.payload.variant) {
                    state.instance = action.payload;
                } else {
                    state.instance = {
                        ...action.payload,
                        variant: state.instance.variant
                    };
                }
            }
        },
        setStatusOfDetailedInstance: (state, action: PayloadAction<InstanceStatus>) => {
            if (state.instance) {
                state.instance.status = action.payload;
            }
        },
        updateOwnedInstancesOffset: (state, action: PayloadAction<{ offset: number; type: 'minted' | 'bought' }>) => {
            if (action.payload.type === 'minted') {
                state.ownedInstances.offsetMinted = action.payload.offset;
            } else {
                state.ownedInstances.offsetBought = action.payload.offset;
            }
        },
        setMintedInstances: (state, action: PayloadAction<DetailedInstance[]>) => {
            state.ownedInstances.mintedInstances = action.payload;
        },
        removeInstance: (state) => {
            state.instance = undefined;
        },
        setAttachments: (state, action: PayloadAction<VariantImage[] | undefined>) => {
            state.attachments = action.payload;
        },
        updateAttachments: (state, action: PayloadAction<VariantImage[]>) => {
            if (state.attachments) {
                state.attachments = state.attachments.map(attachment => {
                    const newAttachment = action.payload.find(newAtt => newAtt.id === attachment.id);
                    if (newAttachment) {
                        return newAttachment;
                    } else {
                        return attachment;
                    }
                })
            }
        },
        resetProductState: (state) => {
            state.pending = false;
            state.fulfilled = false;
            state.error = '';
        }
    },
    extraReducers: builder => {
        builder
            .addCase(addFile.fulfilled, (state, action) => {
                state.fileIds = [...state.fileIds, action.payload.id ?? 0];
            })
            .addCase(publishInstancesOfVariant.fulfilled, (state) => {
                state.fulfilled = true;
            })
            .addCase(createProduct.fulfilled, (state, action: PayloadAction<Product>) => {
                state.product = { ...action.payload, productAttributes: [] };
                state.additionalAttributes = action.payload.productAttributes ?? [];
                state.fulfilled = true;
            })
            .addCase(editInstance.fulfilled, (state, action: PayloadAction<DetailedInstance>) => {
                state.instance = action.payload;
                state.fulfilled = true;
            })
            .addCase(fetchProducts.fulfilled, (state, action: PayloadAction<Product[]>) => {
                state.products = action.payload;
            })
            .addCase(fetchVariants.pending, (state) => {
                state.allVariants.pending = true;
            })
            .addCase(fetchVariants.fulfilled, (
                state, action: PayloadAction<PaginationModel<Variant[]>>
            ) => {
                state.allVariants = {
                    ...action.payload,
                    fulfilled: true,
                    pending: false
                };
            })
            .addCase(fetchVariants.rejected, (
                state, action
            ) => {
                state.allVariants.error = (action.payload as ErrorResponse).status ? (action.payload as ErrorResponse).status.toString() : 'error';
                state.allVariants.pending = false;
            })
            .addCase(fetchMoreVariants.pending, (state) => {
                state.moreVariants.pending = true;
            })
            .addCase(fetchMoreVariants.fulfilled, (
                state, action: PayloadAction<PaginationModel<Variant[]>>
            ) => {
                state.moreVariants = {
                    ...action.payload,
                    fulfilled: true,
                    pending: false
                };
            })
            .addCase(fetchMoreVariants.rejected, (
                state, action
            ) => {
                state.moreVariants.error = (action.payload as ErrorResponse).status ? (action.payload as ErrorResponse).status.toString() : 'error';
                state.moreVariants.pending = false;
            })
            .addCase(fetchStaticPageVariants.pending, (state) => {
                state.staticPageVariants.pending = true;
            })
            .addCase(fetchStaticPageVariants.fulfilled, (
                state, action: PayloadAction<PaginationModel<Variant[]>>
            ) => {
                state.staticPageVariants = {
                    ...action.payload,
                    fulfilled: true,
                    pending: false
                };
            })
            .addCase(fetchStaticPageVariants.rejected, (
                state, action
            ) => {
                state.staticPageVariants.error = (action.payload as ErrorResponse).status ? (action.payload as ErrorResponse).status.toString() : 'error';
                state.staticPageVariants.pending = false;
            })
            .addCase(fetchElements.pending, (state, action: any) => {
                state.variantInstancesElements.push({
                    teaserIdentifier: action.meta.arg.identifier,
                    fulfilled: false,
                    data: [],
                    maxNumber: 0,
                    pagination: { page: 1, size: 0 },
                    pending: true
                })
            })
            .addCase(fetchElements.fulfilled, (
                state, action: PayloadAction<{ data: Variant[], elementType: StrapiTeaserElementTypes, teaserIdentifier: number, filter: FilterItem[], maxElements: string }>
            ) => {
                const updatedTeaserState = {
                    data: action.payload.data,
                    fulfilled: true,
                    pending: false,
                    maxNumber: parseInt(action.payload.maxElements),
                    pagination: {
                        page: parseInt(action.payload.filter[1].value),
                        size: parseInt(action.payload.filter[0].value)
                    },
                    teaserIdentifier: action.meta.arg.identifier
                };
                state.variantInstancesElements.splice(state.variantInstancesElements.indexOf(state.variantInstancesElements.find(teaser => teaser.teaserIdentifier === action.meta.arg.identifier)), 1, updatedTeaserState)
            })
            .addCase(fetchElements.rejected, (
                state, action
            ) => {
                state.variantInstancesElements.splice(state.variantInstancesElements.indexOf(state.variantInstancesElements.find(teaser => teaser.teaserIdentifier === action.meta.arg.identifier)), 1)
            })
            .addCase(fetchUniqueInstances.pending, (state) => {
                state.uniqueInstances.pending = true;
            })
            .addCase(fetchUniqueInstances.fulfilled, (state, action: PayloadAction<PaginationModel<DetailedInstance[]>>) => {
                state.uniqueInstances = {
                    ...action.payload,
                    fulfilled: true,
                    pending: false
                };
            })
            .addCase(fetchUniqueInstances.rejected, (state, action) => {
                state.uniqueInstances.error = (action.payload as ErrorResponse).status ? (action.payload as ErrorResponse).status.toString() : 'error';
            })
            .addCase(fetchOwnedInstances.pending, (state) => {
                state.ownedInstances.pending = true;
                state.ownedInstances.status = undefined;
            })
            .addCase(fetchOwnedInstances.fulfilled, (
                state,
                action: PayloadAction<{ minted: DetailedInstance[]; not_minted: DetailedInstance[]; }>
            ) => {
                state.ownedInstances.pending = false;
                state.ownedInstances.status = 200;
                state.ownedInstances.boughtInstances = action.payload.not_minted;
                state.ownedInstances.mintedInstances = action.payload.minted;
            })
            .addCase(fetchOwnedInstances.rejected, (state, action) => {
                state.ownedInstances.status = (action.payload as ErrorResponse)?.status;
                state.pending = false;
            })
            .addCase(fetchComingSoonVariants.pending, (state) => {
                state.comingSoonVariants.pending = true;
            })
            .addCase(fetchComingSoonVariants.fulfilled, (state, action: PayloadAction<PaginationModel<Variant[]>>) => {
                state.comingSoonVariants = {
                    ...action.payload,
                    fulfilled: true,
                    pending: false
                };
            })
            .addCase(fetchComingSoonVariants.rejected, (state, action) => {
                state.comingSoonVariants.error = (action.payload as ErrorResponse).status ? (action.payload as ErrorResponse).status.toString() : 'error';
                state.comingSoonVariants.pending = false;
            })
            .addCase(fetchFeaturedVariants.pending, (state) => {
                state.featuredVariants.pending = true;
            })
            .addCase(fetchFeaturedVariants.fulfilled, (state, action: PayloadAction<Variant[]>) => {
                state.featuredVariants.pending = false;
                state.featuredVariants.fulfilled = true;
                state.featuredVariants.data = action.payload;
            })
            .addCase(fetchFeaturedVariants.rejected, (state, action) => {
                state.featuredVariants.error = (action.payload as ErrorResponse).status ? (action.payload as ErrorResponse).status.toString() : 'error';
                state.featuredVariants.pending = false;
            })
            .addCase(fetchProduct.fulfilled, (state, action: PayloadAction<Product>) => {
                state.product = action.payload;
                state.variants = action.payload.variants;
                state.additionalAttributes = action.payload.productAttributes ?? [];
            })
            .addCase(fetchInstance.fulfilled, (state, action: PayloadAction<DetailedInstance>) => {
                state.instance = action.payload;
            })
            .addCase(getVariant.fulfilled, (state, action: PayloadAction<Variant>) => {
                state.variant = action.payload;
                state.additionalFiles = action.payload.additionalFiles;
                state.variantAttributes = action.payload.variantAttributes;
            })
            .addCase(fetchAllPublishers.fulfilled, (state, action: PayloadAction<User[]>) => {
                state.publishers = action.payload;
            })
            .addCase(fetchAllOriginators.pending, (state) => {
                state.originators = [];
            })
            .addCase(fetchAllOriginators.fulfilled, (state, action: PayloadAction<User[]>) => {
                state.originators = action.payload;
            })
            .addCase(deleteProduct.fulfilled, (state, action: PayloadAction<number>) => {
                state.adminProducts = state.adminProducts.filter(
                    (product) => product.product.id !== action.payload
                );
                state.adminProductsReleased = state.adminProductsReleased.filter(
                    (product) => product.product.id !== action.payload
                );
            })
            .addCase(deleteInstance.fulfilled, (
                state, action: PayloadAction<{ instanceId: number, variantId: number, productId: number }>
            ) => {
                state.adminProducts = state.adminProducts.map((group) => {
                    if (action.payload.productId === group.product.id) {
                        return {
                            product: group.product,
                            variants: group.variants.map((vari) => {
                                if (vari.id === action.payload.variantId) {
                                    return {
                                        ...vari,
                                        salesAttribute: undefined
                                    }
                                } else {
                                    return vari;
                                }
                            })
                        };
                    } else {
                        return group;
                    }
                });
                state.adminProductsReleased = state.adminProductsReleased.map((group) => {
                    if (action.payload.productId === group.product.id) {
                        return {
                            product: group.product,
                            variants: group.variants.map(
                                (vari) => {
                                    if (vari.id === action.payload.variantId) {
                                        return {
                                            ...vari,
                                            salesAttribute: undefined
                                        }
                                    } else {
                                        return vari;
                                    }
                                }
                            )
                        };
                    } else {
                        return group;
                    }
                });
            })
            .addCase(deleteVariant.fulfilled, (
                state,
                action: PayloadAction<number>
            ) => {
                state.pending = false;
                state.adminProducts = state.adminProducts.map((group) => {
                    return {
                        product: group.product,
                        variants: group.variants.filter(
                            (vari) => vari.id !== action.payload
                        )
                    };
                });
                state.adminProductsReleased = state.adminProductsReleased.map((group) => {
                    return {
                        product: group.product,
                        variants: group.variants.filter(
                            (vari) => vari.id !== action.payload
                        )
                    };
                });
            })
            .addCase(getProductWithVariant.fulfilled, (
                state,
                action: PayloadAction<{
                    data: ProductWithVariants[];
                    pagination: Pagination;
                    maxItems: number;
                }>
            ) => {
                state.adminProducts = action.payload.data;
                state.productPagination = action.payload.pagination;
                state.adminProductsMaxItems = action.payload.maxItems;
            })
            .addCase(getReleasedProductWithVariant.fulfilled, (
                state,
                action: PayloadAction<{
                    data: ProductWithVariants[];
                    pagination: Pagination;
                    maxItems: number;
                }>
            ) => {
                state.pending = false;
                state.adminProductsReleased = action.payload.data;
                state.productPaginationReleased = action.payload.pagination;
                state.adminProductsReleasedMaxItems = action.payload.maxItems;
            })
            .addCase(editProduct.pending, (state) => {
                state.fulfilled = false;
            })
            .addCase(editProduct.fulfilled, (state, action: PayloadAction<Product>) => {
                state.product = { ...action.payload, productAttributes: [] };
                state.additionalAttributes = action.payload.productAttributes ?? [];
                state.fulfilled = true;
            })
            .addCase(fetchVariantsOfProduct.fulfilled, (state, action: PayloadAction<Variant[]>) => {
                state.variants = action.payload;
            })
            .addCase(editVariant.pending, (state) => {
                state.fulfilled = false;
            })
            .addCase(editVariant.fulfilled, (state, action: PayloadAction<Variant>) => {
                state.variant = { ...action.payload, variantAttributes: [] };
                state.additionalAttributes = action.payload.variantAttributes ?? [];
                state.fulfilled = true;
            })
            .addCase(editVariantForProduct.pending, (state) => {
                state.fulfilled = false;
            })
            .addCase(editVariantForProduct.fulfilled, (state, action: PayloadAction<Variant>) => {
                state.adminProducts = state.adminProducts.map(group => {
                    return {
                        product: group.product,
                        variants: group.variants?.map(variant => action.payload?.id === variant?.id ? action.payload : variant) ?? []
                    }
                });
                state.adminProductsReleased = state.adminProductsReleased.map(group => {
                    return {
                        product: group.product,
                        variants: group.variants?.map(variant => action.payload?.id === variant?.id ? action.payload : variant) ?? []
                    }
                });
                state.fulfilled = true;
            })
            .addCase(addVariant.pending, (state) => {
                state.error = '';
                state.fulfilled = false;
            })
            .addCase(addVariant.fulfilled, (
                state,
                action: PayloadAction<Variant>
            ) => {
                state.variant = { ...action.payload, variantAttributes: [] };
                state.additionalAttributes = action.payload.variantAttributes ?? [];
                state.fulfilled = true;
                state.pending = false;
            })
            .addCase(fetchProductByName.fulfilled, (state, action: PayloadAction<Product[]>) => {
                state.fulfilled = true;
                state.product = action.payload?.[0];
            })
            .addCase(fetchOriginatorForProduct.fulfilled, (state, action: PayloadAction<User[]>) => {
                state.fulfilled = true;
                if (state.product) state.product.originators = action.payload;
            })
            .addCase(fetchPublisherForProduct.fulfilled, (state, action: PayloadAction<User[]>) => {
                state.fulfilled = true;
                if (state.product && action.payload.length > 0) state.product.publisher = action.payload[0];
            })
            .addCase(fetchDetailedInstances.fulfilled, (state, action: PayloadAction<PaginationModel<DetailedInstance[]>>) => {
                state.detailedInstances = {
                    ...state.detailedInstances,
                    ...action.payload,
                    fulfilled: true,
                    pending: false,
                };
            })
            .addCase(fetchDetailedInstances.rejected, (state, action) => {
                state.detailedInstances.error = (action.payload as ErrorResponse).status ? (action.payload as ErrorResponse).status?.toString() : 'error';
                state.detailedInstances.pending = false;
            })
            .addCase(fetchDetailedInstances.pending, (state) => {
                state.detailedInstances.pending = true;
                state.error = '';
            })
            .addCase(fetchMoreInstances.fulfilled, (state, action: PayloadAction<PaginationModel<DetailedInstance[]>>) => {
                state.moreInstances = {
                    ...action.payload,
                    fulfilled: true,
                    pending: false,
                };
            })
            .addCase(fetchMoreInstances.rejected, (state, action) => {
                state.moreInstances.error = (action.payload as ErrorResponse).status ? (action.payload as ErrorResponse).status?.toString() : 'error';
                state.moreInstances.pending = false;
            })
            .addCase(fetchMoreInstances.pending, (state) => {
                state.moreInstances.pending = true;
                state.error = '';
            })
            .addCase(fetchMyOanInstances.fulfilled, (state, action) => {
                state.myOanInstances = {
                    ...state.myOanInstances,
                    data: action.payload,
                    fulfilled: true,
                    pending: false,
                };
            })
            .addCase(fetchMyOanInstances.rejected, (state, action) => {
                state.myOanInstances.error = (action.payload as ErrorResponse).status ? (action.payload as ErrorResponse).status.toString() : 'error';
                state.pending = false;
            })
            .addCase(fetchAttachments.fulfilled, (state, action: PayloadAction<VariantImage[]>) => {
                state.attachments = action.payload;
            })
            .addMatcher(
                isProductSlicePending,
                (state: ProductState) => {
                    state.pending = true;
                    state.fulfilled = false;
                    state.error = '';
                }
            )
            .addMatcher(
                isProductSliceFinished,
                (state: ProductState) => {
                    state.pending = false;
                }
            )
            .addMatcher(
                isProductSliceFulfilled,
                (state: ProductState) => {
                    state.fulfilled = true;
                }
            )
            .addMatcher(
                isProductSliceRejected,
                (state: ProductState, action: PayloadAction<ErrorResponse>) => {
                    state.error = action.payload.status ? action.payload.status.toString() : 'error';
                    state.pending = false;
                }
            )
    }
});

export const {
    addAdditionalAttribute,
    removeAdditionalAttribute,
    removeFile,
    resetDetailProduct,
    resetDetailVariant,
    setDetailProduct,
    updateAdditionalAttribute,
    addAdditionalFile,
    removeAdditionalFile,
    setFilter,
    updateOwnedInstancesOffset,
    updateDetailedInstance,
    setMintedInstances,
    setStatusOfDetailedInstance,
    removeInstance,
    setAttachments,
    updateAttachments,
    resetProductState,
    updateAllVariantsSorting
} = productSlice.actions;
export default productSlice.reducer;
