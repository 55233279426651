import {Action, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ChangePasswordData} from '../../api/models/change-password-data';
import {ErrorResponse} from '../../api/models/error-response';
import {LoginData} from '../../api/models/login-data';
import {RegisterData} from '../../api/models/register-data';
import AuthenticationControllerService from '../../api/services/authentication-controller.service';
import RegistrationControllerService from '../../api/services/registration-controller.service';
import UsersService from '../../api/services/users.service';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';

interface AuthentificationState {
    changePasswordStatus?: number;
    hasRegistered: boolean;
    hasConfirmedRegistration?: boolean;
    loggedIn?: boolean;
    jwt: string;
    loginStatus?: number;
    pending: boolean;
    authRoutingActive: boolean;
}

const initialState: AuthentificationState = {
    hasRegistered: false,
    jwt: '',
    pending: false,
    authRoutingActive: false
};

const isAuthSlicePending = (action: Action) => {
    return action.type.startsWith('authentification') && action.type.endsWith('pending');
}

const isAuthSliceFinished = (action: Action) => {
    return action.type.startsWith('authentification') &&
        (action.type.endsWith('rejected') || action.type.endsWith('fulfilled'));
}

export const login = createAsyncThunk(
    'authentification/login',
    async ({emailOrUsername, password}: LoginData, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const authenticationController = new AuthenticationControllerService();
            const response = await authenticationController.authenticateUser({
                username: emailOrUsername,
                password
            });
            return response.data.jwt;
        }, rejectWithValue);
    }
);

export const createUser = createAsyncThunk(
    'authentification/createUser',
    async (regData: RegisterData, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const registrationController = new RegistrationControllerService();
            await registrationController.register(regData);
        }, rejectWithValue);
    }
);

export const changePassword = createAsyncThunk(
    'authentification/changePassword',
    async (data: ChangePasswordData, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const usersService = new UsersService();
            const changePasswordResponse = await usersService.changePassword(data);
            return changePasswordResponse.data;
        }, rejectWithValue);
    }
);

export const logout = createAsyncThunk(
    'authentification/logout',
    async (_, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const authenticationController = new AuthenticationControllerService();
            await authenticationController.logout();
            return;
        }, rejectWithValue);
    }
);

export const fetchAccessTokenFromRefreshToken = createAsyncThunk(
    'authentification/fetchAccessTokenFromRefreshToken',
    async (_, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const authenticationController = new AuthenticationControllerService();
            const response = await authenticationController.refreshToken();
            return response.data.jwt;
        }, rejectWithValue);
    }
);

export const authentificationSlice = createSlice({
    name: 'authentification',
    initialState,
    reducers: {
        setHasRegistered: (state, action: PayloadAction<boolean>) => {
            state.hasRegistered = action.payload;
        },
        removeChangePasswordStatus: (state) => {
            state.changePasswordStatus = undefined;
        },
        setNewJwt: (state, action: PayloadAction<string>) => {
            state.jwt = action.payload;
        },
        resetLoginState: (state) => {
            state.loginStatus = undefined;
        },
        setHasConfirmedRegistration: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.hasConfirmedRegistration = action.payload;
        },
        setAuthRoutingActive: (state, action: PayloadAction<boolean>) => {
            state.authRoutingActive = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loginStatus = undefined;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.jwt = action.payload;
                state.loginStatus = 200;
                state.loggedIn = true;
            })
            .addCase(login.rejected, (state, action) => {
                state.loginStatus = (action.payload as ErrorResponse).status;
            })
            .addCase(logout.fulfilled, (state) => {
                state.jwt = '';
                state.loggedIn = false;
            })
            .addCase(createUser.fulfilled, (state) => {
                state.hasRegistered = true;
            })
            .addCase(fetchAccessTokenFromRefreshToken.fulfilled, (state, action) => {
                state.jwt = action.payload;
                state.loggedIn = true;
            })
            .addCase(changePassword.pending, (
                state
            ) => {
                state.changePasswordStatus = undefined;
            })
            .addCase(changePassword.fulfilled, (state) => {
                state.changePasswordStatus = 200;
            })
            .addMatcher(
                isAuthSlicePending,
                (state: AuthentificationState) => {
                    state.pending = true;
                }
            )
            .addMatcher(
                isAuthSliceFinished,
                (state: AuthentificationState) => {
                    state.pending = false;
                }
            )
    }
});

export const {
    setHasRegistered,
    setNewJwt,
    setHasConfirmedRegistration,
    removeChangePasswordStatus,
    resetLoginState,
    setAuthRoutingActive
} = authentificationSlice.actions;
export default authentificationSlice.reducer;
