import {AxiosPromise} from 'axios';

import {backend} from '../../utils/_config/axios';
import {ContractDTO, CreateSmartContractData} from '../models/create-smart-contract-data';

export default class SmartContractService {
    public createSmartContract(smartContract: CreateSmartContractData): AxiosPromise<CreateSmartContractData> {
        return backend(true).post('api/smartContracts/createContract', smartContract);
    }

    public getAllContractsForVariant(variantId: number): AxiosPromise<ContractDTO[]> {
        return backend(true).get(`api/smartContracts/contractsAllByVariant/${variantId}`);
    }

    public getVariantContract(variantId: number): AxiosPromise<ContractDTO> {
        return backend(true).get(`api/smartContracts/contractByVariant/${variantId}`);
    }

    public getAllContractsForProduct(productId: number): AxiosPromise<ContractDTO[]> {
        return backend(true).get(`api/smartContracts/contractsAllByProduct/${productId}`);
    }

    public getProductContract(productId: number): AxiosPromise<ContractDTO> {
        return backend(true).get(`api/smartContracts/contractByProduct/${productId}`);
    }

    public getPublisherContract(publisherId: number): AxiosPromise<ContractDTO> {
        return backend(true).get(`api/smartContracts/contractByPublisher/${publisherId}`);
    }
}