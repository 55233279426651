import {useRouter} from 'next/dist/client/router';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {resetErrorCodes, RootState, setAuthRoutingActive, useAppSelector} from '../../../redux';
import {AccountRoutePath, RoutePath} from '../../../utils/_enums/route-path.enum';

export const Redirector: React.FC = ({children}) => {
    const router = useRouter();
    const loggedIn = useAppSelector((state: RootState) => (state.authentification.loggedIn));
    const dispatch = useDispatch();

    useEffect(() => {
        router.events.on('routeChangeComplete', () => dispatch(resetErrorCodes()));
        return () => {
            router.events.off('routeChangeComplete', () => dispatch(resetErrorCodes()));
        };
    }, [router.events]);

    useEffect(() => {
        if (loggedIn !== undefined) {
            if (!loggedIn) {
                if (router.pathname.includes(RoutePath.MY_ACCOUNT)
                    || router.pathname.includes(RoutePath.CREATE_PRODUCT_PAGE)
                    || router.pathname.includes(RoutePath.CREATE_VARIANT_PAGE)
                    || router.pathname.includes(RoutePath.CREATE_INSTANCES)
                    || router.pathname.includes(RoutePath.TRANSACTIONS)
                    || router.pathname.includes(RoutePath.ADMIN_PRODUCT_LIST)) {
                    dispatch(setAuthRoutingActive(true));
                    router.push(RoutePath.HOME)
                        .then(() => dispatch(setAuthRoutingActive(false)))
                        .catch(() => dispatch(setAuthRoutingActive(false)));
                }
            } else {
                if (router.pathname === RoutePath.LOGIN
                    || router.pathname === RoutePath.REGISTER) {
                    dispatch(setAuthRoutingActive(true));
                    router.push(RoutePath.MY_ACCOUNT + AccountRoutePath.PROFILE)
                        .then(() => dispatch(setAuthRoutingActive(false)))
                        .catch(() => dispatch(setAuthRoutingActive(false)));
                }
            }
        }
    }, [loggedIn]);

    return <>{children}</>;
};
