import {AxiosPromise} from 'axios';

import {backend} from '../../utils/_config/axios';

export default class AccountingReportControllerService {
    public getAccountingReport(dateFrom: string, dateTo: string, publisherIds?: number[]): AxiosPromise {
        let url;
        if (!publisherIds) {
            url = `/reports/getZipWithPublisherReports/${dateFrom}/${dateTo}`;
        } else if (publisherIds.length === 1) {
            url = `/reports/getReportsFile/${publisherIds[0]}/${dateFrom}/${dateTo}`;
        } else {
            url = `/reports/getZipWithPublisherReports/${dateFrom}/${dateTo}?userId=${publisherIds.join('&userId=')}`;
        }
        return backend(true).get(url, {responseType: 'blob'});
    }

    public sendReportsToMail(dateFrom: string, dateTo: string, emails: string[], publisherIds?: number[]): AxiosPromise {
        const emailQueryParams = 'email=' + emails.join('&email=');
        const publisherQueryParams = publisherIds ? 'userId=' + publisherIds.join('&userId=') : undefined;
        return backend(true)
            .get(`/reports/sendZipForGivenPublishers/${dateFrom}/${dateTo}?${emailQueryParams}
            ${publisherQueryParams ? '&' + publisherQueryParams : ''}`);
    }
}
