import {Action, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AxiosResponse} from 'axios';

interface GlobalState {
    errorCodes: number[];
}

const initialState: GlobalState = {
    errorCodes: []
}

const isRejectedAction = (action: Action) => {
    return action.type.endsWith('rejected');
}

const isPendingAction = (action: Action) => {
    return action.type.endsWith('pending');
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        resetErrorCodes: (state) => {
            state.errorCodes = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                isRejectedAction,
                (state: GlobalState, action: PayloadAction<AxiosResponse>) => {
                    if(action.payload && !action.payload?.config?.url?.includes('/refreshtoken')) {
                        state.errorCodes.push(action.payload.data && action.payload.data.code ?
                            action.payload.data.code : action.payload.status);
                    }
                }
            )
            .addMatcher(
                isPendingAction,
                (state: GlobalState) => {
                    state.errorCodes = []
                }
            )
    }
});

export {isRejectedAction};
export const {
    resetErrorCodes
} = globalSlice.actions;
export default globalSlice.reducer;
