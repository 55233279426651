import { useRouter } from 'next/router';
import React from 'react';
import { useEffect,useState } from 'react';

import styles from './loading-bar.module.scss';

function LoadingBar() {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const incrementProgress = () => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 1;
                }
                return prevProgress;
            });
        };

        const interval = setInterval(incrementProgress, 20);
        return () => clearInterval(interval);
    }, []);

    const progressBarStyles = {
        width: `${progress}%`,
    };

    return (<div className={styles['loading-bar']} style={progressBarStyles} />);
}

interface LayoutProps {
    children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
    const [isLoading, setIsLoading] = useState(false);
    const router = useRouter();

    useEffect(() => {
        const handleStart = () => {
            setIsLoading(true);
        };

        const handleComplete = () => {
            setIsLoading(false);
        };

        const handleError = () => {
            setIsLoading(false);
        };

        const handleBeforeUnload = () => {
            setIsLoading(true);
        };

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleComplete);
        router.events.on('routeChangeError', handleError);
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleComplete);
            router.events.off('routeChangeError', handleError);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [router]);

    return (
        <div>
            {isLoading && <LoadingBar />}
            {children}
        </div>
    );
}

export default Layout;