import {Web3Provider} from '@ethersproject/providers';

import {isServer} from './isServer';

const ethereumInstance = isServer() ? null : (window as any).ethereum;

let web3Instance: Web3Provider;

const setWeb3Instance = (web3: Web3Provider): void => {
    web3Instance = web3;
};

export {
    ethereumInstance,
    setWeb3Instance,
    web3Instance
};
