/* eslint-disable no-unused-vars */

export enum RoutePath {
    DEFAULT_PAGE = '/',
    REGISTER = '/register',
    LOGIN = '/login',
    AUTHENTIFICATION_LOCALE = '/:country/:locale/auth',
    REGISTER_CONFIRMATION = '/register-confirmation',
    REGISTER_CONFIRMATION_LOCALE = '/:country/:locale/register-confirmation',
    NEWSLETTER_CONFIRM = '/newsletter-confirm',
    NEWSLETTER_CONFIRM_LOCALE = '/:country/:locale/newsletter-confirm',
    MY_ACCOUNT = '/my-account',
    MY_ACCOUNT_LOCALE = '/:country/:locale/my-account',
    ERROR_PAGE = '/error-page',
    ERROR_PAGE_LOCALE = '/:country/:locale/error-page',
    TYPO_PAGE = '/page/:typoPage',
    TYPO_PAGE_LOCALE = '/:country/:locale/page/:typoPage',
    ARTICLE_PAGE = '/s/:articlePage',
    ARTICLE_PAGE_LOCALE = '/:country/:locale/s/:articlePage',
    USER_MANAGEMENT = '/user-management',
    USER_MANAGEMENT_LOCALE = '/:country/:locale/user-management',
    CREATE_PRODUCT_PAGE = '/create-product',
    CREATE_PRODUCT_PAGE_LOCALE = '/:country/:locale/create-product',
    CREATE_VARIANT_PAGE = '/create-variant/',
    CREATE_VARIANT_PAGE_LOCALE = '/:country/:locale/create-variant',
    CHECKOUT_PATH = '/checkout',
    CHECKOUT_PATH_LOCALE = '/:country/:locale/checkout',
    DIGITAL_ORIGINALS = '/digital-originals',
    DIGITAL_ORIGINALS_LOCALE = '/:country/:locale/digital-originals',
    HOME = '/',
    COMING_SOON_PAGE_LOCALE = '/:country/:locale/coming-soon',
    CHECKOUT_CONFIRMATION = '/checkout-confirmation',
    CHECKOUT_CONFIRMATION_LOCALE = '/:country/:locale/checkout-confirmation',
    CHECKOUT_CONFIRMATION_CRYPTO = '/checkout-confirmation-crypto',
    CHECKOUT_CONFIRMATION_CRYPTO_LOCALE = '/:country/:locale/checkout-confirmation-crypto',
    ADMIN_PRODUCT_LIST = '/admin-product-list',
    CHECKOUT = '/checkout',
    CREATE_INSTANCES = '/create-instances/',
    PRODUCTS = '/products',
    MY_OAN = '/user',
    RESET_PASSWORD = '/reset-password/',
    TRANSACTIONS = '/transactions',
    CREATE_SMART_CONTRACT_PAGE = '/create-smart-contract'
}

export enum AccountRoutePath {
    PROFILE = '/profile',
    NOTIFICATIONS = '/notifications',
    PORTFOLIO_AND_GALLERY = '/portfolio-and-gallery',
    MANAGE_WALLET = '/manage-wallet',
    CHANGE_PASSWORD = '/change-password',
    INVOICE_ADDRESS = '/invoice-address',
    SUPPORT_AND_CONTACT = '/support-and-contact'
}

export enum CheckoutPath {
    COUNTRY,
    PAYMENT_METHOD
}
