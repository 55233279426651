import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ImageType} from 'react-images-uploading/dist/typings';

import {User} from '../../api/models/user';
import GeoIpService from '../../api/services/geo-ip.service';
import UsersService from '../../api/services/users.service';
import {isValidGeoIp} from '../../utils/_helper/valid-country-helper';
import {ReduxStateModel} from '../../utils/_models/redux-state-model';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';
import {ErrorResponse} from '../../api/models/error-response';

interface UserState {
    fetchUser: ReduxStateModel;
    updateUser: ReduxStateModel;
    updateProfilePicture: ReduxStateModel;
    checkGeoblocking: ReduxStateModel;
    user?: User;
    isGeoblocked: boolean;
}

const initialState: UserState = {
    fetchUser: {
        pending: false
    },
    updateUser: {
        pending: false
    },
    updateProfilePicture: {
        pending: false
    },
    checkGeoblocking: {
        pending: false
    },
    isGeoblocked: false
};

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (data: User, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const userService = new UsersService();
            const userResponse = await userService.updateUser(data);
            return userResponse.data;
        }, rejectWithValue);
    }
);

export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    async (_, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const usersService = new UsersService();
            const userResponse = await usersService.fetchUser();
            return userResponse.data;
        }, rejectWithValue);
    }
);

export const checkGeoblocking = createAsyncThunk(
    'user/checkGeoblocking',
    async (_, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const geoService = new GeoIpService();
            const geoResponse = await geoService.getGeoIp();
            return !isValidGeoIp(geoResponse.data.country);
        }, rejectWithValue);
    }
);

export const updateProfilePicture = createAsyncThunk(
    'user/updateProfilePicture',
    async (imageType: ImageType, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const userService = new UsersService();
            const formData = new FormData();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formData.append('file', imageType);
            await userService.updateProfilePicture(formData);
        }, rejectWithValue);
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        removeUserStatus: (state) => {
            state.updateUser.status = undefined;
            state.checkGeoblocking.status = undefined;
            state.updateProfilePicture.status = undefined;
            state.fetchUser.status = undefined;
        },
        clearUser: (state) => {
            state.user = undefined;
        },
        setWallet: (state, data: PayloadAction<string>) => {
            if (state.user) {
                state.user.wallet = data.payload;
            }
        }
    },
    extraReducers: {
        [fetchUser.pending.type]: (state) => {
            state.fetchUser.pending = true;
            state.fetchUser.status = undefined;
        },
        [fetchUser.fulfilled.type]: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.fetchUser.status = 200;
            state.fetchUser.pending = false;
        },
        [fetchUser.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.fetchUser.pending = false;
            state.fetchUser.status = action.payload.status;
        },
        [checkGeoblocking.pending.type]: (state) => {
            state.checkGeoblocking.pending = true;
            state.checkGeoblocking.status = undefined;
        },
        [checkGeoblocking.fulfilled.type]: (state, action: PayloadAction<boolean>) => {
            state.isGeoblocked = action.payload;
            state.checkGeoblocking.pending = false;
            state.checkGeoblocking.status = 200;
        },
        [checkGeoblocking.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.checkGeoblocking.pending = false;
            state.checkGeoblocking.status = action.payload.status;
        },
        [updateUser.pending.type]: (state) => {
            state.updateUser.pending = true;
            state.updateUser.status = undefined;
        },
        [updateUser.fulfilled.type]: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.updateUser.pending = false;
            state.updateUser.status = 200;
        },
        [updateUser.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.updateUser.pending = false;
            state.updateUser.status = action.payload.status;
        },
        [updateProfilePicture.pending.type]: (state) => {
            state.updateProfilePicture.pending = true;
            state.updateProfilePicture.status = undefined;
        },
        [updateProfilePicture.fulfilled.type]: (state) => {
            state.updateProfilePicture.pending = false;
            state.updateProfilePicture.status = 200;
            if (state.user) {
                state.user.profileImageUrl = state.user.profileImageUrl + '?t=' + new Date().getTime();
            }
        },
        [updateProfilePicture.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.updateProfilePicture.pending = false;
            state.updateProfilePicture.status = action.payload.status;
        }
    }
});

export const {
    removeUserStatus,
    clearUser,
    setWallet
} = userSlice.actions;
export default userSlice.reducer;
