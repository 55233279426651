import {Contract} from 'ethers';

import tokenContractABI from '../../utils/_config/token-contract-abi';
import {web3Instance} from '../../utils/_helper/window-instances';

export default class TokenContractService {

    private contract: Contract;

    constructor(contractAddress: string) {
        const signer = web3Instance.getSigner();
        this.contract = new Contract(contractAddress, tokenContractABI, signer);
    }

    public mint(tokenId: number, signature: string): Promise<any> {
        return this.contract
            .mint(tokenId, signature, {value: 0});
    }
}
