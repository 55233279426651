import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CreatokenBackendPayload, CreatokenContractPayload} from '../../api/models/creatoken-payload';
import {EthFixPrice} from '../../api/models/eth-fix-price';
import {FullVariant} from '../../api/models/full-variant';
import CreatokenControllerService from '../../api/services/creatoken-controller.service';
import CreatokenDropContractService from '../../api/services/creatoken-drop-contract.service';
import {ReduxStateModel} from '../../utils/_models/redux-state-model';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';
import {ErrorResponse} from '../../api/models/error-response';

interface CreatokenState {
    buyCreatokenModel: ReduxStateModel<EthFixPrice>;
    transactionHash?: string;
    creatokenVariantsModel: ReduxStateModel<FullVariant[]>
}

const initialState: CreatokenState = {
    buyCreatokenModel: {
        pending: false
    },
    creatokenVariantsModel: {
        pending: false,
        data: []
    }
};

export const fetchCreatokenVariants = createAsyncThunk(
    'creatoken/fetchCreatokenVariants',
    async (_, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const creatokenController = new CreatokenControllerService();
            const res = await creatokenController.getCreatokenVariants();
            return res.data.variants;
        }, rejectWithValue);
    }
);

export const buyCreatoken = createAsyncThunk(
    'creatoken/buyCreatoken',
    async ({backendData, contractData}:
               { backendData: CreatokenBackendPayload, contractData: CreatokenContractPayload },
           {rejectWithValue, dispatch}) => {
        return await reduxThunkWrapper(async () => {
            const creatokenController = new CreatokenControllerService();
            const buyResponse = await creatokenController.buyCreatoken(backendData);

            const creatokenDropContract = new CreatokenDropContractService(buyResponse.data.contract);
            const tx = await creatokenDropContract.mint(contractData);

            dispatch(setTransactionHash(tx.hash));
            await tx.wait();
        }, rejectWithValue)
    }
);

export const creatokenSlice = createSlice({
    name: 'creatokenSlice',
    initialState,
    reducers: {
        resetBuyCreatokenState: (state) => {
            state.buyCreatokenModel.status = undefined;
        },
        setTransactionHash: (state, showDialog: PayloadAction<string | undefined>) => {
            state.transactionHash = showDialog.payload
        }
    },
    extraReducers: {
        [buyCreatoken.pending.type]: (state) => {
            state.buyCreatokenModel.pending = true;
            state.buyCreatokenModel.status = undefined;
        },
        [buyCreatoken.fulfilled.type]: (state, action: PayloadAction<EthFixPrice>) => {
            state.buyCreatokenModel.status = 200;
            state.buyCreatokenModel.data = action.payload;
            state.buyCreatokenModel.pending = false;
        },
        [buyCreatoken.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.buyCreatokenModel.status = action.payload.status;
            state.buyCreatokenModel.pending = false;
        },
        [fetchCreatokenVariants.pending.type]: (state) => {
            state.creatokenVariantsModel.pending = true;
            state.creatokenVariantsModel.status = undefined;
        },
        [fetchCreatokenVariants.fulfilled.type]: (state, action: PayloadAction<FullVariant[]>) => {
            state.creatokenVariantsModel.status = 200;
            state.creatokenVariantsModel.data = action.payload;
            state.creatokenVariantsModel.pending = false;
        },
        [fetchCreatokenVariants.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.creatokenVariantsModel.status = action.payload.status;
            state.creatokenVariantsModel.pending = false;
        },
    }
});

export const {
    resetBuyCreatokenState,
    setTransactionHash
} = creatokenSlice.actions;
export default creatokenSlice.reducer;
