import { AxiosPromise } from 'axios';

import { backend } from '../../utils/_config/axios';
import { AuthData } from '../models/auth-data';
import {AuthenticateData} from '../models/authenticate-data';

export default class AuthenticationControllerService {
    public authenticateUser(authData: AuthData): AxiosPromise<AuthenticateData> {
        return backend().post('/authenticate', authData);
    }

    public refreshToken(): AxiosPromise<AuthenticateData> {
        return backend().post('/refreshtoken');
    }

    public logout(): AxiosPromise {
        return backend().post('/deleteForLogout');
    }
}
