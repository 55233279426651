import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {SocialNetworks} from '../../api/models/social-networks';
import SocialNetworksService from '../../api/services/social-networks.service';
import {ReduxStateModel} from '../../utils/_models/redux-state-model';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';
import {ErrorResponse} from '../../api/models/error-response';

interface SocialNetworksState {
    fetchSocialNetworks: ReduxStateModel;
    updateSocialNetworks: ReduxStateModel;
    socialNetworks?: SocialNetworks;
}

const initialState: SocialNetworksState = {
    fetchSocialNetworks: {
        pending: false
    },
    updateSocialNetworks: {
        pending: false
    }
};

export const updateSocialNetworks = createAsyncThunk(
    'socialNetworks/updateSocialNetworks',
    async (data: SocialNetworks, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const socialNetworksService = new SocialNetworksService();
            const socialNetworksResponse = await socialNetworksService.updateSocialNetworks(data);
            return socialNetworksResponse.data;
        }, rejectWithValue);
    }
);

export const fetchSocialNetworks = createAsyncThunk(
    'socialNetworks/fetchSocialNetworks',
    async (_, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const socialNetworksService = new SocialNetworksService();
            const socialNetworksResponse = await socialNetworksService.fetchSocialNetworks();
            return socialNetworksResponse.data;
        }, rejectWithValue);
    }
);

const socialNetworksSlice = createSlice({
    name: 'socialNetworks',
    initialState,
    reducers: {
        removeSocialNetworksStatus: (state) => {
            state.fetchSocialNetworks.status = undefined;
            state.updateSocialNetworks.status = undefined;
        }
    },
    extraReducers: {
        [fetchSocialNetworks.pending.type]: (state) => {
            state.fetchSocialNetworks.pending = true;
        },
        [fetchSocialNetworks.fulfilled.type]: (state, action: PayloadAction<SocialNetworks>) => {
            state.socialNetworks = action.payload;
            state.fetchSocialNetworks.pending = false;
        },
        [fetchSocialNetworks.rejected.type]: (state) => {
            state.fetchSocialNetworks.pending = false;
        },
        [updateSocialNetworks.pending.type]: (state) => {
            state.updateSocialNetworks.pending = true;
            state.updateSocialNetworks.status = undefined;
        },
        [updateSocialNetworks.fulfilled.type]: (state, action: PayloadAction<SocialNetworks>) => {
            state.socialNetworks = action.payload;
            state.updateSocialNetworks.pending = false;
            state.updateSocialNetworks.status = 200;
        },
        [updateSocialNetworks.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.updateSocialNetworks.pending = false;
            state.updateSocialNetworks.status = action.payload.status
        }
    }
});

export const {
    removeSocialNetworksStatus
} = socialNetworksSlice.actions;
export default socialNetworksSlice.reducer;
