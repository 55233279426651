import {StrapiImage} from '../models/strapi/strapi-image';
import {useLanguageDependentDataSSr} from './useLanguageDependentDataSsr.hook';

export const useCardText =
    useLanguageDependentDataSSr<UseCardTextData>('card-text');

interface UseCardTextData {
    informAndBuy: string;
    inform: string;
    detailsAndActions: string;
    learnMore: string;
    bid: string;
    tokenId: string;
    pricePaid: string;
    copy: string;
    copies: string;
    readMore: string;
    defaultAuthorImage: StrapiImage;
}
