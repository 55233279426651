import { AxiosPromise } from 'axios';

import { backend } from '../../utils/_config/axios';
import { RegisterData } from '../models/register-data';

export default class RegistrationControllerService {
    public register(regData: RegisterData): AxiosPromise<{ jwt: string }> {
        return backend().post('/register', regData);
    }
}
