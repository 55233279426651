import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {User} from '../../api/models/user';
import UsersService from '../../api/services/users.service';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';
import {ErrorResponse} from '../../api/models/error-response';

interface MyOanState {
    pending: boolean;
    user?: User;
    fetchStatus?: number;
}

const initialState: MyOanState = {
    pending: false
};

export const fetchMyOanUser = createAsyncThunk(
    'myOan/fetchUserByUsername',
    async (username: string, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const usersService = new UsersService();
            const userResponse = await usersService.getMyOanUser(username);
            if (userResponse.data[0]) {
                return userResponse.data[0];
            } else {
                throw Error('No user found');
            }
        }, rejectWithValue);
    }
);

const myOanSlice = createSlice({
    name: 'myOan',
    initialState,
    reducers: {
        clearUser: (state) => {
            state.user = undefined;
        }
    },
    extraReducers: {
        [fetchMyOanUser.pending.type]: (state) => {
            state.pending = true;
            state.fetchStatus = undefined;
        },
        [fetchMyOanUser.fulfilled.type]: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.pending = false;
            state.fetchStatus = 200;
        },
        [fetchMyOanUser.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.pending = false;
            state.fetchStatus = action.payload?.status ?? 400;
        },
    }
});

export const {
    clearUser
} = myOanSlice.actions;
export default myOanSlice.reducer;
