import dayjs from 'dayjs';

export const toCountdown = (seconds: number): string => {
    let h: number | string = Math.floor(seconds / (60 * 60));
    let m: number | string = Math.floor((seconds / 60) % 60);
    let s: number | string = Math.floor((seconds) % 60);

    h = (h < 10) ? '0' + h : h;
    m = (m < 10) ? '0' + m : m;
    s = (s < 10) ? '0' + s : s;

    return h + ':' + m + ':' + s;
}

export const dateToInputString = (date?: string | Date): string => {
    return dayjs(date).format('YYYY-MM-DDTHH:mm');
}

export const onlyDate = (date?: string | Date): string => {
    return dayjs(date).format('YYYY-MM-DD');
}

export const dateToISOString = (date: string | Date): string => {
    return dayjs(date).toISOString();
}

export const formatDate = (date?: string | Date | number): string => {
    return date ? new Date(date).toLocaleDateString([], {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }) : '';
}

export const formatDateAndTime = (date?: string | Date | number): string => {
    return date ? new Date(date).toLocaleString([], {
        timeZone: 'Europe/Warsaw',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short'
    }) : '';
}

export const formatTime = (date?: string | Date | number): string => {
    return date ? new Date(date).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) : '';
}

export const isDateInFuture = (date?: string | Date, defaultValue = false): boolean => {
    return date ? new Date(date) > new Date() : defaultValue;
}

export const isDateInPast = (date?: string | Date, defaultValue = false): boolean => {
    return date ? new Date() > new Date(date) : defaultValue;
}
