import {Contract} from 'ethers';

import creatokenDropContractABI from '../../utils/_config/creatoken-drop-contract-abi';
import {web3Instance} from '../../utils/_helper/window-instances';
import {CreatokenContractPayload} from '../models/creatoken-payload';

export default class CreatokenDropContractService {

    private contract: Contract;

    constructor(contractAddress: string) {
        const signer = web3Instance.getSigner();
        this.contract = new Contract(contractAddress, creatokenDropContractABI, signer);
    }

    public mint(data: CreatokenContractPayload): Promise<any> {
        return this.contract.mint(data.tier, {value: data.price});
    }
}
