export enum TypographyVariantsEnum {
    HEADING1 = 'heading1',
    HEADING2_BOLD = 'heading2-bold',
    VARIANT_HEADING = 'variant-heading',
    HEADING2_LIGHT = 'heading2-light',
    HEADING3_LIGHT = 'heading3-light',
    HEADING3_BOLD = 'heading3-bold',
    HEADING4_LIGHT = 'heading4-light',
    HEADING4_BOLD = 'heading4-bold',
    HEADING5 = 'heading5',
    HEADING5_LIGHT = 'heading5-light',
    HEADING6 = 'heading6',
    INTROTEXT = 'introtext',
    CONTAINER = 'container',
    TOPLINE_OR_LABEL = 'toplineOrLabel',
    BODY = 'body',
    BODY_SMALL = 'body-small',
    CAPTION = 'caption',
    INPUT = 'input',
    BUTTON = 'button',
    STAGE = 'stage',
    QUOTE = 'quote',
    ERROR_MESSAGE = 'errorMessage',
    MOBILENAVLINK = 'mobile-nav-link',
    TOAST = 'toast',
    TABLE_TITLE = 'table-title',
    TABLE_VALUE = 'table-value',
    KEYWORD_TITLE = 'keyword-title',
    LINK = 'link-text',
    LINK_SMALL = 'link-small',
    PARAGRAPH = 'paragraph',
    FAQ_HEADING = 'faq-heading',
    FOOTER_PARAGRAPH = 'footer-paragraph',
    FOOTER_LINK = 'footer-link',
    QUOTE_TEXT = 'quote-text',
    COUNTDOWN = 'countdown'
}
